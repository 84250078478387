<template>
  <Sidebar v-model:visible="sidebarOpen" :header="headerTitle" position="right"
           class="mission-control mc-contact-sidebar" @hide="closeSidebar">
    <div class="primary">
      <section class="contact-data">
        <header class="section-header">
          <h2>Contact Details</h2>
          <Button size="small" @click="editing = true" :disabled="editing">Edit Details</Button>
        </header>
        <div class="section-body">
          <ContactDetails v-if="!displayDetailsEditor" :contact="contact" />
          <ContactEditor
              v-if="displayDetailsEditor"
              :contact="contact"
              :isNewContactForm="isNewContactForm"
              @contactCreated="relayContactCreated"
              @contactUpdated="relayContactUpdated"
              @contactDeleted="relayContactDeleted"/>
        </div>
      </section>
      <Sessions :contactId="contact.ID" v-show="!displayDetailsEditor" />
    </div>
    <div class="secondary">
      <Notes :notes="contactNotes" parentModel="contact" :parentId="contact.ID" v-show="!displayDetailsEditor" />
    </div>
  </Sidebar>
</template>

<script>
import qs from "qs";
import axios from "axios";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import InputNumber from "primevue/inputnumber";
import InputMask from 'primevue/inputmask';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Sidebar from 'primevue/sidebar';
import ContactDetails from './ContactDetails.vue';
import ContactEditor from './ContactEditor.vue';
import Sessions from './Sessions.vue';
import Notes from './Notes.vue';

export default {
  components: {
    Button,
    InputText,
    InputNumber,
    InputMask,
    Dropdown,
    Calendar,
    Sidebar,
    ContactDetails,
    ContactEditor,
    Notes,
    Sessions
  },
  props:      {
    contact: {
      type:    Object,
      default: {}
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editing:      false,
      contactNotes: [],
      sidebarOpen: false
    };
  },
  computed: {
    isNewContactForm() {
      return !this.contact.hasOwnProperty('created_at')
    },
    displayDetailsEditor() {
      return this.editing || this.isNewContactForm;
    },
    headerTitle() {
      return (this.contact.first_name && this.contact.last_name) ? this.contact.first_name + ' ' + this.contact.last_name : 'New Contact'
    }
  },
  watch:    {
    visible() {
      // console.log('visible changed');
      this.sidebarOpen = this.visible;
    },
    contact() {
      // console.log('Sidebar Property (Contact) Changed')
      if (this.contact.hasOwnProperty('ID')) {
        // Fetch Contact Notes
        let ContactNotesRequestData = {
          action: "get_all_fc_" + "mc_contact_notes",
          data:   {
            model_id: this.contact.ID
          },
        };
        axios.post(
            window.fc_data.ajax_url,
            qs.stringify(ContactNotesRequestData)
        )
            .then((response) => {
              // console.log(response.data);
              this.contactNotes = response.data.notes;
            })
            .catch((error) => {
              console.log(error);
            });
      } else {
        this.contactNotes = [];
      }
    }
  },
  methods:  {
    closeSidebar() {
      // console.log('closing sidebar');
      this.$emit('contactClosed');
    },
    relayContactCreated(contact) {
      // console.log('relayContactCreated(contact)')
      // console.log(contact)
      this.editing = false;
      this.$emit('contactCreated', contact)
    },
    relayContactUpdated(contact) {
      // console.log('relayContactUpdated(contact)')
      // console.log(contact)
      this.editing = false;
      this.$emit('contactUpdated', contact)
    },
    relayContactDeleted(contact) {
      // console.log('relayContactDeleted(contact)')
      // console.log(contact)
      this.editing = false;
      this.$emit('contactDeleted', contact)
    }
  }
}
</script>