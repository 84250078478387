<template>
  <section class="contact-notes">
    <header class="section-header">
      <h2>{{ parentModel }} Notes</h2>
      <Button size="small" @click="creatingNote = true" :disabled="creatingNote || editingNote" label="Add Note" />
    </header>
    <div class="section-body">
      <form class="module note" v-if="creatingNote">
        <div class="module-header">
          New Note
        </div>
        <div class="module-body">
          <Editor v-model="newNoteContent" editorStyle="height: 120px" />
        </div>
        <footer class="module-footer">
          <Button size="small" @click="createNote" label="Save" />
        </footer>
      </form>
      <Note v-for="note in notes" :note="note" :parent-object="parentModel" @stateChange="handleNoteChange" />
    </div>
  </section>
</template>

<script>
import qs from "qs";
import axios from "axios";
import Button from "primevue/button";
import Editor from 'primevue/editor';
import Note from './Note.vue';

export default {
  components: {
    Button,
    Editor,
    Note
  },
  props:      {
    notes:       {
      type:    Array,
      default: []
    },
    parentModel: {
      type:    String,
      default: ''
    },
    parentId:    {
      type:    [Number, String],
      default: 0
    }
  },
  data() {
    return {
      editing:        false,
      newNoteContent: '',
      editingNote: false,
      creatingNote:   false,
    };
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-us', {
        weekday: "long", year: "numeric", month: "short", day: "numeric"
      })
    },
    handleNoteChange(args){
      console.log(args);
      this.editingNote = args.editing;
    },
    createNote() {
      // console.log('creating contact note');
      let data = {
        action: "create_fc_" + "mc_" + this.parentModel + "_notes",
        data:   {
          content:  this.newNoteContent,
          model_id: this.parentId
        },
      };
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            // console.log(response.data)
            this.notes.push(response.data.note)
            this.creatingNote = false;
            this.newNoteContent = '';
          })
          .catch((error) => {
            console.log(error)
          });
    }
  }
}
</script>