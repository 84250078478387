<template>
    <div class="glide__arrows" data-glide-el="controls">
        <button class="glide__arrow glide__arrow--left"  aria-label="carousel left button navigation control" data-glide-dir="<">prev</button>
        <button class="glide__arrow glide__arrow--right"  aria-label="carousel right button navigation control" data-glide-dir=">">next</button>
    </div>
</template>

<script>
export default {}
</script>
