<template>
  <div class="paginator">
    <span v-if="currentPage > 1" class="prev" @click="handlePaginationChange(currentPage - 1)">Prev</span>
    <ul class="pages">
      <li v-for="pageNumber in pageCount"  :class="{'current': pageNumber === currentPage}">
        <a @click="handlePaginationChange(pageNumber)">{{ pageNumber }}</a>
      </li>
    </ul>
    <span v-if="pageCount > 1 && currentPage !== pageCount" class="next"  @click="handlePaginationChange(currentPage + 1)">Next</span>
  </div>
</template>

<script>

export default {
  props:      {
    items: {
      type:    Array,
      default: []
    },
    perPage: {
      type:    Number,
      default: 16
    }
  },
  data() {
    return {
      currentPage: 1,
      previousItemsCount: 0,
    };
  },
  mounted() {
    this.previousItemsCount = this.itemsCount;
    this.handlePaginationChange(this.currentPage);
  },
  computed: {
    itemsCount() {
      return this.items.length;
    },
    pageCount() {
      return Math.ceil(this.itemsCount / this.perPage);
    },
    lastPageItemCount() {
      // Get the remainder of total items divided by perPage count
      return this.itemsCount % this.perPage;
    }
  },
  watch: {
    items() {
      this.handlePaginationChange(this.currentPage);
    }
  },
  methods: {
    handlePaginationChange(pageNumber) {
      // console.log(pageNumber)
      this.$parent.paginatedItems = [];
      if(this.itemsCount) {
        this.currentPage = pageNumber;

        // If the items count changes, we need to reset to page 1;
        if(this.itemsCount !== this.previousItemsCount) {
          this.previousItemsCount = this.itemsCount;
          this.currentPage = 1;
        }

        // Determine start and end points based on current page
        let start = (this.currentPage - 1) * this.perPage;
        let end = this.currentPage * this.perPage;

        // determine if it is the last page,
        if(this.currentPage === this.pageCount) {
          // calculate the number of items on the last page
          end = (this.currentPage * this.perPage) - this.perPage + this.lastPageItemCount;
        }

        // Push the paginated items to the parent component to then pass to ItemCards
        for(let i = start; i < end; i++){
          this.$parent.paginatedItems.push(this.items[i])
        }
      }
    },
  }
}
</script>
