<template>
    <component :is="tag" class="accordion">
        <slot></slot>
    </component>
</template>

<script>

export default {
    name:  'Accordion',
    props: {
        tag:          {
            type:    String,
            default: 'div'
        },
        singleOpen:   {
            type:    Boolean,
            default: false
        },
        noToggleIcon: {
            type:    Boolean,
            default: false
        },
    },
    data() {
        return {
            // accordionItems: []
        };
    },
    mounted() {
        let vueInstanceWrapper = this.$el.parentElement;
        vueInstanceWrapper.classList.remove('visually-hidden');

        const accordionItems = this.$el.children;

        for (let i = 0; i < accordionItems.length; i++) {
            // console.log(this.accordionItems[i])
            let itemHeading = accordionItems[i].querySelector(".accordion__heading");
            let itemHeadingTitle = itemHeading.querySelector(".accordion__title");
            let itemHeadingIcon = itemHeading.querySelector(".accordion__icon");
            let itemBody = accordionItems[i].querySelector(".accordion__body");

            if(this.noToggleIcon) {
                itemHeadingIcon.style.display = "none";
            }

            if (this.singleOpen) {
                // console.log('single open');
                itemHeading.addEventListener('click', function () {
                    for (let j = 0; j < accordionItems.length; j++) {
                        let itemBody = accordionItems[j].querySelector(".accordion__body");
                        if (j === i) {
                            if (accordionItems[j].classList.contains('accordion__item--active')) {
                                itemBody.style.height = 0;
                                accordionItems[j].classList.remove('accordion__item--active');
                            } else {
                                itemBody.style.height = itemBody.scrollHeight + 'px';
                                accordionItems[j].classList.add('accordion__item--active');
                            }
                        } else {
                            itemBody.style.height = 0;
                            accordionItems[j].classList.remove('accordion__item--active');
                        }
                    }
                });
            } else {
                // console.log('not single open');
                itemHeading.addEventListener('click', function () {
                    for (let j = 0; j < accordionItems.length; j++) {
                        let itemBody = accordionItems[j].querySelector(".accordion__body");
                        if (j === i) {
                            if (accordionItems[j].classList.contains('accordion__item--active')) {
                                itemBody.style.height = 0;
                                accordionItems[j].classList.remove('accordion__item--active');
                            } else {
                                itemBody.style.height = itemBody.scrollHeight + 'px';
                                accordionItems[j].classList.add('accordion__item--active');
                            }
                        }
                    }
                });
            }

            // Update the height of the accordion item when the user clicks inside it
            // this allows the accordion to have dynamically sized elements like tabs inside it.
            accordionItems[i].addEventListener('click', function (event) {
                if(accordionItems[i].classList.contains("accordion__item--active")) {
                    itemBody.style.height = itemBody.scrollHeight + 'px';
                }
            });
        }
    },
    methods: {}
}
</script>
