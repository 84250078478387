<template>
    <div class="location-card">
        <figure class="block image" v-html="location.featured_image.tag">

        </figure>
        <div class="block content">
            <div class="card-header">
                <h4 class="card-title">{{ location.post_title }}</h4>
            </div>
            <div class="card-body">
                <p class="location-wrapper">
                    <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4283_9919)">
                            <path
                                d="M8.49998 0.25C5.39948 0.25 2.87702 2.77246 2.87702 5.87297C2.87702 6.99909 3.20712 8.08114 3.8327 9.00983L8.49998 16.25L13.1673 9.00983C13.7929 8.08114 14.123 6.99909 14.123 5.87297C14.123 2.77246 11.6005 0.25 8.49998 0.25ZM8.49998 8.68445C6.94973 8.68445 5.6885 7.42322 5.6885 5.87297C5.6885 4.32272 6.94973 3.06148 8.49998 3.06148C10.0502 3.06148 11.3115 4.32272 11.3115 5.87297C11.3115 7.42322 10.0502 8.68445 8.49998 8.68445Z" />
                        </g>
                    </svg>
                    <span class="location">{{ location.address_1 ? location.address_1 + " " : "" }}{{ location.city ? location.city + ", " : "" }}{{ location.state ? location.state + " " : "" }}{{ location.postal_code ? location.postal_code : "" }}</span>
                </p>
                <p class="phone-wrapper">
                    <svg width="17" height="17" viewBox="0 0 17 17"  xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4283_9626)">
                            <path d="M12.4104 16.25C11.1918 16.25 9.69675 15.6889 8.04968 14.5832C4.71951 12.3485 0.822341 7.7623 0.52029 4.66338C0.423708 3.67226 0.672243 2.87256 1.25916 2.28564L3.2948 0.25L7.43982 4.39502L7.10837 4.72646C6.57966 5.25527 5.71326 6.11982 5.09382 6.73506C5.66365 7.57187 6.76365 8.80849 7.57527 9.62011C8.34353 10.3877 9.23249 11.0864 10.0337 11.6216C10.6108 11.0406 11.3877 10.2614 12.023 9.6247L12.355 9.29297L16.5 13.4389L14.489 15.4498C13.9539 15.985 13.2475 16.25 12.4104 16.25Z" />
                        </g>
                    </svg>

                    <a v-if="location.phone_number" :href="'tel:' + location.phone_number.replace(/[^+\d]+/g, '')"
                       class="phone">{{ location.phone_number }}</a>
                </p>
            </div>
            <div class="card-footer">
                <a :href="location.permalink">{{ cardViewBtnText }}</a>
            </div>
        </div>
    </div>
</template>

<script>


import themConfig from './../../../config.json';

const themeData = window[themConfig.theme_prefix + 'data'];

export default {
    props: {
        location: {
            type:    Object,
            default: {}
        },
        cardViewBtnText: {
            type: String,
            default: "View"
        }

    },
    data() {
        return {}
    },
    mounted() {

    },
    methods: {}
};
</script>
