<template>
  <a :href="item.url" target="_blank" class="card" :style="cardSettings.styles.wrapper">
    <div class="card_image">
      <img :src="item.cover" :alt="item.title_main + ' cover'" />
    </div>
    <div class="card__content">
      <div class="card__header">
        <h3 class="h5 card__title" v-html="item.title_main + ' ' + (item.title ? item.title : '')" :style="cardSettings.styles.title"></h3>
      </div>
      <div class="card__body" :style="cardSettings.styles.body">
        <p v-html="item.author"></p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type:    Object,
      default: {}
    },
    cardSettings: {
      type:    Object,
      default: {}
    },
  },
  methods:  {

  }
}
</script>