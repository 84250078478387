<template>
    <div class="glide__bullets" data-glide-el="controls[nav]">
        <span :class='"glide__bullet " + (parseInt(this.$parent.currentSlide) !== (n - 1) ? bulletColorClass : "") + (parseInt(this.$parent.currentSlide) === (n - 1) ? bulletActiveColorClass : "")'  v-for="n in count" :data-glide-dir="'='+(n - 1)" aria-label="carousel bullet navigation control" @click="handleBulletClick(n - 1)"></span>
    </div>
</template>

<script>
export default {
    props: {
        count: {
            type: Number
        },
        bulletActiveColorClass: {
            type:    String,
            default: ''
        },
        bulletColorClass: {
            type:    String,
            default: ''
        }
    },
    mounted() {
    },
    methods: {
        handleBulletClick(bulletNumber) {
            console.log(bulletNumber)
            console.log(this.$parent.currentSlide)
        }
    }
}
</script>
