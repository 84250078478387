<template>
  <div class="new-pb-form-wrapper">
    <form @submit.prevent="create()">
<!--      {{ objectId }}-->
      <select v-model="parentId">
        <option selected disabled>Parent</option>
        <option v-for="parentOption in parentOptions" :value="parentOption.ID">{{ parentOption.title }}</option>
      </select>
      <input v-model="title" :placeholder="parentId ? $parent.getPersonalBestByID(parentId)['title'] : 'Title'" :disabled="parentId"/>
      <input v-model="description" :placeholder="parentId ? $parent.getPersonalBestByID(parentId)['description'] : 'Description'" :disabled="parentId"/>
      <input v-model="measurement" :placeholder="parentId ? $parent.getPersonalBestByID(parentId)['measurement'] : 'Measurement'" :disabled="parentId"/>
      <input v-model="record" placeholder="Record"/>
      <Datepicker ref="eventDate"
                  v-model="eventDate"
                  model-type="yyyy-MM-dd"
                  :format="formatDate(eventDate)"
                  placeholder="Select a date"/>
      <button type="submit">
        <span ref="save" class="text">Save</span>
        <span ref="saving" class="loading"></span>
      </button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Datepicker from "@vuepic/vue-datepicker";

export default {
  components: {Datepicker},
  props: {
    personalBests: {
      type: Array
    },
    personalBest: {
      type: [Object, Boolean],
      default: false
    },
    parentOptions: {
      type: Array
    }
  },

  data() {
    return {
      objectId: this.personalBest ? this.personalBest.ID : null,
      parentId: this.personalBest ? this.personalBest.parent_id : null,
      title: this.personalBest ? this.personalBest.title : null,
      description: this.personalBest ? this.personalBest.description : null,
      record: this.personalBest ? this.personalBest.record : null,
      measurement: this.personalBest ? this.personalBest.measurement : null,
      eventDate: this.personalBest ? this.personalBest.event_date : new Date(),
    };
  },
  mounted() {
    // console.log("personal-best-form vue vomponent mounted!");
    // console.log('parent options: ');
    // console.log(this.parentOptions);
    // console.log('personal bests: ');
    // console.log(this.personalBests);
  },
  computed: {
    createdDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate() + 1;

      return `${year}-${month}-${day}`;
    },
  },
  methods: {
    formatDate(eventDate) {
      const date = new Date(eventDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate() + 1;

      return `${month}/${day}/${year}`;
    },
    create() {
      this.$refs.save.classList.add('inactive');
      this.$refs.saving.classList.add('active');
      let data = {
        parent_id: this.parentId,
        title: this.title,
        description: this.description,
        record: this.record,
        measurement: this.measurement,
        event_date: this.eventDate,
      };
      let formData = new FormData;
      formData.append('action', 'create_fc_' + 'wt_personal_bests');
      formData.append('parent_id', this.parentId);
      formData.append('title', this.title);
      formData.append('description', this.description);
      formData.append('record', this.record);
      formData.append('measurement', this.measurement);
      formData.append('event_date', this.eventDate);
      // console.log('formData prepared to create personalBest:');
      // for (let key of formData.entries()) {
      //   console.log(key[0] + ', ' + key[1]);
      // }
      axios.post(window.fc_data.ajax_url, formData)
          .then(response => {
            console.log('PersonalBest Creation Response:');
            console.log(response.data);
            const object = response.data.object;
            this.$emit("personalBestCreated", object);

            if (parseInt(object.parent_id) === 0) {
              console.log('Updating personalBestParents with new option');
              this.parentOptions[object.ID] = object.title;
            }
            this.$forceUpdate();
            this.$refs.saving.classList.remove('active');
            this.$refs.save.classList.remove('inactive');
          })
          .catch(error => {
            console.log(error);
          });
    },
  }
}
</script>