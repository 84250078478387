<template>
  <section class="sessions">
    <header class="section-header">
      <h2>Sessions</h2>
      <Button
          size="small"
          @click="creatingSession = true"
          :disabled="creatingSession || editingSession"
          label="Add Session"
      />
    </header>
    <div class="section-body">
      <form class="module session" v-if="creatingSession">
        <div class="module-header">
          New Session
        </div>
        <div class="module-body">
          <fieldset class="flex-cols gap-10 flex-cols-3">
            <Calendar
                :modelValue="newSessionDateTime"
                @update:modelValue="updateNewSessionDateTime"
                dateFormat="yy-mm-dd"
                showTime
                :stepMinute=5
                hourFormat=12  />
            <Dropdown
                v-model="newSession.type"
                :options="[{label: 'Virtual', value: 'virtual'}, {label: 'In-Person', value: 'in-person'}]"
                optionLabel="label"
                optionValue="value"
                placeholder="Select type"
            />
            <Dropdown
                v-model="newSession.location"
                :options="[{label: 'Home', value: 'home'}, {label: 'Office', value: 'office'}]"
                optionLabel="label"
                optionValue="value"
                placeholder="Select location"
            />
          </fieldset>
        </div>
        <footer class="module-footer">
          <Button
              size="small"
              @click="createSession"
              label="Save"
          />
        </footer>
      </form>
      <Session
          v-for="session in sessions"
          :session="session"
          parentObject="contact"
          @stateChange="handleSessionChange"
      />
    </div>
  </section>
</template>

<script>
import qs from "qs";
import axios from "axios";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import InputNumber from "primevue/inputnumber";
import InputMask from 'primevue/inputmask';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Session from './Session.vue';

export default {
  components: {
    Button,
    InputText,
    InputNumber,
    InputMask,
    Dropdown,
    Calendar,
    Session
  },
  props:      {
    contactId: {
      type:    [Number, String],
      Default: 0
    }
  },
  data() {
    return {
      sessions:        [],
      newSession:      {},
      newSessionDateTime:      new Date(),
      editingSession:  false,
      creatingSession: false
    };
  },
  mounted() {
    let sessionsRequestData = {
      action: "get_all_fc_" + "mc_sessions",
      data:   {
        where: {
          column: 'contact_id',
          value:  this.contactId
        }
      },
    };
    axios.post(
        window.fc_data.ajax_url,
        qs.stringify(sessionsRequestData)
    )
        .then((response) => {
          // console.log(response.data);
          this.sessions = response.data.sessions;
        })
        .catch((error) => {
          console.log(error);
        });
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-us', {
        weekday: "long", year: "numeric", month: "short", day: "numeric"
      })
    },
    handleSessionChange(args) {
      this.editingSession = args.editing;
    },
    subtractHours(date) {
      let timeZoneOffset = date.getTimezoneOffset()/60;// Determine timezone from browser
      const dateCopy = new Date(date);
      dateCopy.setHours(dateCopy.getHours() - timeZoneOffset);
      return dateCopy;
    },
    updateNewSessionDateTime(newSessionDateTime) {
      this.newSessionDateTime = newSessionDateTime;
      this.newSession.date_time = this.subtractHours(newSessionDateTime);
      console.log(this.newSession.date_time);
    },
    createSession() {
      console.log('creating session');
      this.newSession.contact_id = this.contactId
      let data = {
        action: "create_fc_" + "mc_sessions",
        data:   this.newSession,
      };
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            console.log(response.data)
            this.newSession.ID = response.data.object_id
            this.sessions.push(this.newSession)
            this.creatingSession = false;
            this.newSession = {};
          })
          .catch((error) => {
            console.log(error)
          });
    }
  }
}
</script>