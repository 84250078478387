<template>
      <div class="actions">
        <Button size="small" @click="generateContacts" label="Generate Contacts" />
        <Button size="small" @click="generateNotes('mc_contact_notes')" label="Generate Contact Notes" />
        <Button size="small" @click="generateSessions" label="Generate Sessions" />
        <Button size="small" @click="generateNotes('mc_session_notes')" label="Generate Session Notes" />
      </div>
</template>

<script>
import qs from "qs";
import axios from "axios";
import Button from "primevue/button";

export default {
  components: {
    Button
  },
  methods:  {
    generateContacts() {
      let data = {
        action: "generate_faker_fc_" + "mc_contacts",
      };
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            console.log(response.data);
            this.$emit('contactsCreated', response.data.contacts)
          })
          .catch((error) => {
            console.log(error)
          });
    },
    generateSessions() {
      let data = {
        action: "generate_faker_fc_" + "mc_sessions",
      };
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error)
          });
    },
    generateNotes(pivotModel) {
      let data = {
        action: "generate_faker_fc_" + pivotModel,
      };
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            console.log(response.data)
          })
          .catch((error) => {
            console.log(error)
          });
    },
  }
}
</script>