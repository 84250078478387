<template>
  <div class="mission-control mc-dashboard">
    <div class="dashboard-header">
      <ContentGenerator
          @contactsCreated="addContacts"
      />
    </div>
    <div class="modules-wrapper">
      <section class="contacts-module">
        <header class="section-header">
          <h2>Contacts</h2>
          <Button size="small" @click="openNewContactForm" label="Add Contact" />
        </header>
        <div class="section-body">
          <DataTable
              :value="clientTableData"
              size="medium"
              stripedRows
              paginator :rows="10"
              selectionMode="single"
              @rowSelect="onRowSelect">
            <Column field="ID" header="ID" sortable />
            <Column field="key" header="Key" sortable />
            <Column field="type" header="Type" sortable />
            <Column field="firstName" header="First Name" sortable />
            <Column field="lastName" header="Last Name" sortable />
          </DataTable>
        </div>
      </section>
    </div>
  </div>

  <ContactSidebar
      :contact="contact"
      :visible="contactSidebarVisible"
      @contactClosed="closeContactSidebar"
      @contactCreated="addContact"
      @contactUpdated="updateContact"
      @contactDeleted="removeContact"
  />
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import ContentGenerator from "./ContentGenerator.vue";
import ContactSidebar from "./ContactSidebar.vue";

export default {
  components: {
    DataTable,
    Column,
    Button,
    ContactSidebar,
    ContentGenerator
  },
  props:      {
    contactData: {
      type:    Array,
      default: []
    }
  },
  data() {
    return {
      contactSidebarVisible: false,
      contact:               {},
      contacts:              this.contactData
    };
  },
  computed: {
    clientTableData() {
      // console.log('setting table data')
      return this.contacts.map((contact, index) => {
        return {
          ID:        contact.ID,
          key:       index,
          type:      contact.type,
          firstName: contact.first_name,
          lastName:  contact.last_name,
        }
      });
    },
  },
  watch:    {
    contactSidebarVisible() {
      if (!this.contactSidebarVisible) {
        this.contact = {};
      }
    }
  },
  methods:  {
    onRowSelect(event) {
      this.contact = this.contacts.find(contact => contact.ID === event.data.ID);
      this.contact.key = event.data.key;
      this.contactSidebarVisible = true;
    },
    openNewContactForm() {
      this.contact = {};
      this.contactSidebarVisible = true;
    },
    closeContactSidebar() {
      // console.log('closeContact()')
      this.contactSidebarVisible = false;
    },
    addContact(contact) {
      // console.log('createContact(contact)')
      // console.log(contact)
      this.contacts.push(contact);
      this.contactSidebarVisible = false;
    },
    addContacts(contacts) {
      // console.log('addContacts(contacts)')
      // console.log(contacts)
      for (let i = 0; i < contacts.length; i++) {
        this.contacts.push(contacts[i]);
      }
    },
    updateContact(contact) {
      // console.log('updateContact(contact)')
      // console.log(contact)
      this.contacts[contact.key] = contact;
      this.contactSidebarVisible = false;
    },
    removeContact(contact) {
      // console.log('removeContact(contact)')
      // console.log(contact)
      this.contacts.splice(contact.key, 1);
      this.contactSidebarVisible = false;
    }
  }
}
</script>