<template>
    <div class="ui-wrapper">
        <div class="toggle-wrapper">
            <a class="button primary" :class="getToggleBtnClass('grid')" @click="toggleView('grid')">Grid View</a>
            <a class="button primary" :class="getToggleBtnClass('map')" @click="toggleView('map')">Map View</a>
        </div>
        <div v-show="viewType === 'grid'" class="location-cards">
            <div class="flex-cols" :class="flexClasses.join(' ')" :style="flexStyles">
                <LocationCard
                    v-for="location in locations"
                    :location="location"
                    :card-view-btn-text="cardViewBtnText"
                />
            </div>
        </div>
        <div v-show="viewType === 'map'" class="map-wrapper">
            <GoogleMap
                :api-key="googleMapsAPIKey"
                style="width: 100%; height: 500px"
                :center="mapCenter"
                :zoom="10"
            >
                <Marker v-for="location in locations"
                        :options="{position: { lat: Number(location.geolocation.split(',')[0]), lng: Number(location.geolocation.split(',')[1]) } }" />
            </GoogleMap>
        </div>
    </div>

</template>

<script>

import LocationCard from './LocationCard.vue'
import {GoogleMap, Marker} from 'vue3-google-map'
import themConfig from './../../../config.json';

const themeData = window[themConfig.theme_prefix + 'data'];

export default {
    components: {GoogleMap, Marker, LocationCard},
    props:      {
        locations:   {
            type:    Array,
            default: []
        },
        mapCenter:   {
            type: Object
        },
        flexClasses: {
            type:    Array,
            default: []
        },
        flexStyles:  {
            type:    String,
            default: ""
        },
        cardViewBtnText: {
            type: String,
            default: "View"
        }
    },
    data() {
        return {
            selectedLocation: 0,
            themeData:        window[themConfig.theme_prefix + 'data'],
            googleMapsAPIKey: themeData.google_maps_api_key,
            viewType:         'grid'
        }
    },
    mounted() {
        // console.log(this.mapCenter);
        console.log(this.locations);
        console.log(this.flexClasses)
        console.log(this.flexStyles)
    },
    methods: {
        toggleView(view) {
            this.viewType = view;
        },
        getToggleBtnClass(view) {
            if (this.viewType === view) {
                return "filled";
            }
            return "hollow";
        }
    }
};
</script>
