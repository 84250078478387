(function () {

    const debounce = (callback, wait) => {
        let timeoutId = null;
        return (...args) => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                callback.apply(null, args);
            }, wait);
        };
    };

    const direction = {
        INITIAL: 0,
        UP:      1,
        DOWN:    2,
    };

    const returnToTopEl = {
        element: document.querySelector('.return-to-top'),
        height:  null,
        addClass(name) {
            // console.log('adding class')
            this.element.classList.add(name);
        },
        removeClass(name) {
            // console.log('removing class')
            this.element.classList.remove(name);
        },
    };

    const scroll = {
        updating:  false,
        current:   null,
        previous:  window.scrollY || document.documentElement.scrollTop,
        direction: {
            current:  direction.INITIAL,
            previous: direction.INITIAL,
        },
        setCurrent(current = null) {
            this.current = current || window.scrollY || document.documentElement.scrollTop;
        },
        updatePrev(prev = null) {
            this.previous = prev || this.current;
        },
        get currDirection() {
            return this.direction.current;
        },
        set currDirection(current) {
            this.direction.current = current;
        },
        get prevDirection() {
            return this.direction.previous;
        },
        set prevDirection(previous) {
            this.direction.previous = previous;
        },
        refreshDirection() {
            if (this.current > this.previous) {
                this.currDirection = direction.DOWN;
            } else if (this.current < this.previous) {
                this.currDirection = direction.UP;
            }
        },
        isUp() {
            return this.currDirection === direction.UP;
        },
        isDown() {
            return this.currDirection === direction.DOWN;
        },
        isDirectionChanged() {
            return this.currDirection !== this.prevDirection;
        }
    };

    function updateReturnToTop() {
        scroll.refreshDirection();

        if (scroll.isDirectionChanged()) {
            toggleReturnToTop();
        }

        scroll.updatePrev();

        if(scroll.current === 0) {
            returnToTopEl.addClass('scroll-top--zero');
        } else {
            returnToTopEl.removeClass('scroll-top--zero');
        }
    }

    function toggleReturnToTop() {
        if (scroll.isUp()) {
            setTimeout(function () {
                returnToTopEl.addClass('visible');
                scroll.updatePrev();
            }, 20);

        } else {
            returnToTopEl.removeClass('visible');
            scroll.updatePrev();
        }
    }

    if (returnToTopEl.element) {

        window.addEventListener('load', function (e) {
            scroll.setCurrent();
            updateReturnToTop();
        });

        window.addEventListener('scroll', function (e) {
            scroll.setCurrent();

            if (!scroll.updating) {
                window.requestAnimationFrame(debounce(function () {
                    updateReturnToTop();
                    scroll.updating = false;
                }, 200));

                scroll.updating = true;
            }
        });

        const trigger = returnToTopEl.element.querySelector('.trigger');
        trigger.addEventListener('click', function (e) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        });
    }
})();
