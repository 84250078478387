(function ($) {
    /**
     * Primary navigation menu
     */
    var primaryNavigation = $('.primary-navigation'),
        subMenus = primaryNavigation.find('ul.sub-menu'),
        menuToggle = $('#js-toggle-nav-main'),
        backButtonMarkup = $('<li class="nav-link-back"><span class="sub-menu-back-button"><span class="sub-menu-back-button-label">Back</span></a></li>'),
        linksWithSubmenus = primaryNavigation.find('li.more > a'),
        body = $('body'),
        mobileNavActive = false,
        activeSubMenu;

    // Open the mobile navigation when the icon is clicked.
    menuToggle.on('click', function () {
        // console.log('click');
        if (!mobileNavActive) {
            return;
        }
        if (body.hasClass('menu-open')) {
            body.removeClass('menu-open');
            primaryNavigation.find('li.more.expanded').removeClass('expanded');
            activeSubMenu = null;
        } else {
            body.addClass('menu-open');
        }
    });

    /**
     * Sub-menus
     */
    if (subMenus.length) {

        // Watch clicks on body to auto close submenu
        $('body').on('touchend', function (e) {
            if (mobileNavActive) return;
            if (activeSubMenu && $(e.target).closest('.expanded').length === 0) {
                activeSubMenu.parent().removeClass('expanded');
                activeSubMenu = null;
            }
        });

        // Prepend the parent anchor link to the submenu.
        $.each(linksWithSubmenus, function (i) {
            var subMenuWrapper = $(this).siblings('.sub-menu-wrapper');
            var subMenu = subMenuWrapper.children('.sub-menu');
            var menuItem = $('<li class="root-menu-item"></li>');
            $(this).clone().appendTo(menuItem);
            subMenu.prepend(menuItem);
        });

        // Prepend a back button to each sub-menu.
        subMenus.prepend(backButtonMarkup);

        // Expand sub-menu when link is clicked.
        linksWithSubmenus.on('click touchend', function (e) {
            if ((!mobileNavActive && e.type !== 'touchend') || $(this).parent().hasClass('expanded')) {
                return;
            }
            e.preventDefault();
            $(e.target).parent().addClass('expanded');
            if (!mobileNavActive && activeSubMenu) {
                activeSubMenu.parent().removeClass('expanded');
            }
            activeSubMenu = $(this);
        });

        // Hide sub-menu when back button is clicked.
        subMenus.find('.sub-menu-back-button').on('click', function (e) {
            if (!mobileNavActive) {
                return;
            }
            $(e.target).closest('.expanded').removeClass('expanded');
        });
    }

    /**
     * Primary Navigation Media Query
     */

    // Handler function for when the Media Query changes.
    function mqChangeHandler(mq) {
        if (mq.matches) {
            mobileNavActive = false;
            body.removeClass('menu-open');
        } else {
            mobileNavActive = true;
            primaryNavigation.find('.expanded').removeClass('expanded');
        }
    }

    // Define a Media Query & event handler for when the
    // navigation functionality changes.
    if (matchMedia) {
        var mq = window.matchMedia("(min-width: 1400px)");
        mq.addListener(mqChangeHandler);
        mqChangeHandler(mq);
    }
})(jQuery);
