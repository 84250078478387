<template>
  <div class="community-members">
    <div class="ui-wrapper" :class="filtersLocationClass" v-if="items.length">
      <ItemFilters
          :items="items"
          :taxonomyFilterData="taxonomyFilters"
          :taxonomyFilterOrders="taxonomyFilterOrders"
          :alphaFilterOrder="alphaFilterOrder"
      />
      <div class="items-wrapper">
        <ItemCards
            :items="paginatedItems"
            :orphanAlignmentClass="orphanAlignmentClass"
            :cardSettings="cardSettings"
            :columnGapStyles="columnGapStyles"
            :rowGapStyles="rowGapStyles"
        />
        <Paginator :items="filteredItems" :perPage="cardsPerPage"></Paginator>
      </div>
    </div>
    <div class="no-items-passed" v-if="!items.length">
      <h2>Sorry, it appears there are no items to display :(</h2>
    </div>
  </div>
</template>

<script>
import ItemCards from './cards/ItemCards.vue';
import ItemFilters from './filters/ItemFilters.vue';
import Paginator from './paginator/Paginator.vue';

export default {
  components: {ItemCards, ItemFilters, Paginator},
  props:      {
    items:                {
      type:    Array,
      default: []
    },
    taxonomyFilters:      {
      type:    Array,
      default: []
    },
    filtersLocationClass: {
      type:    String,
      default: 'hidden'
    },
    taxonomyFilterOrders: {
      type:    Array,
      default: []
    },
    alphaFilterOrder: {
      type:    Number,
      default: 1
    },
    orphanAlignmentClass: {
      type:    String,
      default: 'justify-content-flex-start'
    },
    cardSettings:         {
      type:    Object,
      default: {
        taxonomies: {}
      }
    },
    columnGapStyles:      {
      type:    String,
      default: ''
    },
    rowGapStyles:         {
      type:    String,
      default: ''
    },
    cardsPerPage:         {
      type:    Number,
      default: 16
    },
  },

  data() {
    return {
      filteredItems:       this.items,
      paginatedItems:      this.items,
      taxonomyFilterTerms: this.initializeTaxonomyFilterTerms(), // referenced in ItemCard
      searchTerms:         [] // referenced in ItemCard
    };
  },
  mounted() {
    // console.log('CollectionItems component mounted');
    // console.log('Community Member :items')
    // console.log(this.items);
    // console.log('Custom Tables :taxonomyFilters')
    // console.log(this.taxonomyFilters);
    // console.log('Custom Tables :cardSettings')
    // console.log(this.cardSettings);
  },
  computed: {},
  methods:  {
    initializeTaxonomyFilterTerms() {
      // console.log('initializeTaxonomyFilterTerms')
      let taxonomyFilterTerms = {};
      for (let i = 0; i < this.taxonomyFilters.length; i++) {
        taxonomyFilterTerms[this.taxonomyFilters[i].plural_name] = [];
      }
      // console.log('Taxonomy Term Filters: ');
      // console.log(this.taxonomyFilterTerms);
      return taxonomyFilterTerms;
    }
  }
}
</script>
