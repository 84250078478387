import Dashboard from "./MissionControl/Dashboard";
import Meals from "./WarriorTracker/Meals";
import PersonalBests from "./WarriorTracker/PersonalBests";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'fc-dashboard':      Dashboard,
    'fc-meals-stats':    Meals,
    'fc-personal-bests': PersonalBests
};
