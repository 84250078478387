<template>
  <div v-if="items.length" class="cards" :class="[orphanAlignmentClass, 'flex-cols flex-cols-1', flexColClasses]" :style="columnGapStyles + ' ' + rowGapStyles">
    <ItemCard
        v-for="item in items"
        :item="item"
        :cardSettings="cardSettings">
    </ItemCard>
  </div>
  <div v-if="!items.length" class="no-results">
    <p class="no-results-title">Sorry!<span>There are no results for this search</span></p>
  </div>
</template>

<script>
import ItemCard from "./ItemCard.vue";

export default {
  components: {ItemCard},
  props:      {
    items:                {
      type:    Array,
      default: []
    },
    orphanAlignmentClass: {
      type:    String,
      default: 'justify-content-flex-start'
    },
    cardSettings:         {
      type:    Object,
      default: {}
    },
    columnGapStyles:      {
      type:    String,
      default: ''
    },
    rowGapStyles:      {
      type:    String,
      default: ''
    },
  },
  computed:   {
    flexColClasses() {
      let classes = this.cardSettings.breakpoints.map((breakpoint) => {
        if (breakpoint.hasOwnProperty('prefix')) {
          return breakpoint.prefix + ":flex-cols-" + breakpoint.value
        } else {
          return "flex-cols-" + breakpoint.value
        }

      });
      return classes.join(" ");
    }
  }
}
</script>