<template>
  <div class="book-list"  @click="testClick">

    <div class="items-wrapper" v-if="items.length">
      <ItemCards
          :items="paginatedItems"
          :orphanAlignmentClass="orphanAlignmentClass"
          :cardSettings="cardSettings"
          :columnGapStyles="columnGapStyles"
          :rowGapStyles="rowGapStyles"
      />
      <Paginator :items="items" :perPage="cardsPerPage"></Paginator>
    </div>

    <div class="no-items-passed" v-if="!items.length">
      <h2>Sorry, it appears there are no items to display :(</h2>
    </div>
  </div>
</template>

<script>
import ItemCards from './cards/ItemCards.vue';
import Paginator from './paginator/Paginator.vue';

export default {
  components: {ItemCards, Paginator},
  props:      {
    items:                {
      type:    Array,
      default: []
    },
    orphanAlignmentClass: {
      type:    String,
      default: 'justify-content-flex-start'
    },
    rowGapStyles:         {
      type:    String,
      default: ''
    },
    columnGapStyles:      {
      type:    String,
      default: ''
    },
    cardsPerPage:         {
      type:    Number,
      default: 16
    },
    cardSettings:         {
      type:    Object,
      default: {
        taxonomies: {}
      }
    },
  },

  data() {
    return {
      paginatedItems:    this.items,
      isBusyGettingList: false
    };
  },
  mounted() {
    // this.getSampleBookList();
    // console.log('Book List Vue Component Mounted!');
    // console.log(this.items);
    // console.log(this.paginatedItems);
    // this.$forceUpdate();
  },
  computed: {},
  methods:  {

    testClick() {
      console.log('testing');
    }
  }
}
</script>
