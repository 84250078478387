<template>
  <tr class="personal-best-row">
    <td>{{ objectId }}</td>
    <td>
      <Datepicker ref="eventDate"
                  v-model="eventDate"
                  model-type="yyyy-MM-dd"
                  :format="formatDate(eventDate)"/>
    </td>
    <td>
      <input v-if="!parentId" v-model="title"/>
      <input v-if="parentId" :value="$parent.getPersonalBestByID(parentId)['title']" disabled/>
    </td>
    <td>
      <input v-if="!parentId" v-model="description"/>
      <input v-if="parentId" :value="$parent.getPersonalBestByID(parentId)['description']" disabled/>
    </td>
    <td>
      <input v-if="!parentId" v-model="measurement"/>
      <input v-if="parentId" :value="$parent.getPersonalBestByID(parentId)['measurement']" disabled/>
    </td>
    <td>
      <input  v-model="record"/>
    </td>
    <td>
      <span ref="update" class="action" @click="update()">Update</span>
      <span ref="updating" class="loading"></span>
    </td>
    <td>
      <span ref="delete" class="action" @click="destroy()">Delete</span>
      <span ref="deleting" class="loading"></span>
    </td>
  </tr>
</template>

<script>
import axios from 'axios';
import Datepicker from '@vuepic/vue-datepicker';

export default {
  components: {Datepicker},
  props: {
    personalBest: {
      type: Object,
    },
    personalBestParents: {
      type: Object,
    },
    index: {
      type: Number,
    }
  },

  data() {
    return {
      objectId: this.personalBest.ID,
      parentId: parseInt(this.personalBest.parent_id),
      title: this.personalBest.title,
      description: this.personalBest.description,
      record: this.personalBest.record,
      measurement: this.personalBest.measurement,
      eventDate: this.personalBest.event_date,
    };
  },
  mounted() {
    // console.log("personal-best vue component mounted!");
  },
  methods: {
    formatDate(eventDate) {
      const date = new Date(eventDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate() + 1;

      return `${month}/${day}/${year}`;
    },
    update() {
      this.$refs.update.classList.add('inactive');
      this.$refs.updating.classList.add('active');
      let formData = new FormData;
      formData.append('action', 'update_fc_' + 'wt_personal_bests');
      formData.append('ID', this.objectId);
      formData.append('parent_id', this.parentId);
      formData.append('title', this.title);
      formData.append('description', this.description);
      formData.append('measurement', this.measurement);
      formData.append('record', this.record);
      formData.append('event_date', this.eventDate);
      console.log('formData prepared to create personalBest:');
      for (let key of formData.entries()) {
        console.log(key[0] + ', ' + key[1]);
      }
      axios.post(window.fc_data.ajax_url, formData)
          .then(response => {
            console.log(response.data);
            this.$refs.updating.classList.remove('active');
            this.$refs.update.classList.remove('inactive');
          })
          .catch(error => {
            console.log(error);
          });
    },
    destroy() {
      this.$refs.delete.classList.add('inactive');
      this.$refs.deleting.classList.add('active');
      let formData = new FormData;
      formData.append('action', 'destroy_fc_' + 'wt_personal_bests');
      formData.append('ID', this.objectId);
      formData.append('index', this.index);
      console.log('formData prepared to create personalBest:');
      for (let key of formData.entries()) {
        console.log(key[0] + ', ' + key[1]);
      }
      axios.post(window.fc_data.ajax_url, formData)
          .then(response => {
            console.log(response.data);
            this.$emit("personalBestDestroyed", response.data.object);
          })
          .catch(error => {
            console.log(error);
          });
    }
  }
}
</script>