<template>
  <div class="filter-aside" :class="{active: isMenuOpen}">
    <h2 class="h5 strong">Search</h2>
    <div class="search-and-toggle-wrapper">
      <div class="search" :class="{'no-mobile-toggle':  !taxonomyFilters.length}">
        <InputText
            type="text"
            v-model="textSearch"
            placeholder="Search"
            size="normal"
            @keyup="didUserPressEnter($event)"
        />
        <span class="action" @click="setSearchInputFilterTerms()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 490.4 490.4">
                <g>
	              <path
                    d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796   s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z    M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z" />
                </g>
          </svg>
        </span>
      </div>
      <span v-if="taxonomyFilters.length" class="mobile-toggle" @click="toggleFilterControls()">
        <svg viewBox="0 0 600 600">
          <g transform="matrix(0.95173205,0,0,0.95115787,13.901174,12.168794)" style="stroke-width:1.05103">
            <path style="stroke-linecap:round;-inkscape-stroke:none"
                  d="M 447.70881 -12.781343 A 42.041451 42.041451 0 0 0 405.66786 29.260344 L 405.66786 50.301721 L 27.434765 50.301721 A 42.041302 42.041302 0 0 0 -14.606185 92.341354 A 42.041302 42.041302 0 0 0 27.434765 134.38304 L 405.66786 134.38304 L 405.66786 155.44906 A 42.041451 42.041451 0 0 0 447.70881 197.49075 A 42.041451 42.041451 0 0 0 489.74976 155.44906 L 489.74976 134.38304 L 573.78036 134.38304 A 42.041302 42.041302 0 0 0 615.82336 92.341354 A 42.041302 42.041302 0 0 0 573.78036 50.301721 L 489.74976 50.301721 L 489.74976 29.260344 A 42.041451 42.041451 0 0 0 447.70881 -12.781343 z M 143.0012 197.48869 A 42.041451 42.041451 0 0 0 100.9582 239.53038 L 100.9582 260.5697 L 27.447078 260.5697 A 42.041302 42.041302 0 0 0 -14.593872 302.61139 A 42.041302 42.041302 0 0 0 27.447078 344.65308 L 100.9582 344.65308 L 100.9582 365.7191 A 42.041451 42.041451 0 0 0 143.0012 407.76078 A 42.041451 42.041451 0 0 0 185.04215 365.7191 L 185.04215 344.65308 L 573.79472 344.65308 A 42.041302 42.041302 0 0 0 615.83567 302.61139 A 42.041302 42.041302 0 0 0 573.79472 260.5697 L 185.04215 260.5697 L 185.04215 239.53038 A 42.041451 42.041451 0 0 0 143.0012 197.48869 z M 279.59427 407.76078 A 42.041451 42.041451 0 0 0 237.55332 449.80042 L 237.55332 470.83974 L 27.447078 470.83974 A 42.041302 42.041302 0 0 0 -14.593872 512.88143 A 42.041302 42.041302 0 0 0 27.447078 554.92106 L 237.55332 554.92106 L 237.55332 575.98913 A 42.041451 42.041451 0 0 0 279.59427 618.02877 A 42.041451 42.041451 0 0 0 321.63522 575.98913 L 321.63522 554.92106 L 573.79472 554.92106 A 42.041302 42.041302 0 0 0 615.83567 512.88143 A 42.041302 42.041302 0 0 0 573.79472 470.83974 L 321.63522 470.83974 L 321.63522 449.80042 A 42.041451 42.041451 0 0 0 279.59427 407.76078 z " />
          </g>
        </svg>
      </span>
    </div>
    <div class="filters">
      <div v-if="taxonomyFilters.length" v-for="(taxonomy, index) in taxonomyFilters" class="filter taxonomy" :class="taxonomy.plural_name" :style="'order:' + taxonomyFilterOrders[index].order + ';'">
        <TaxonomyFilter :index="index" :taxonomyData="taxonomy"></TaxonomyFilter>
      </div>
      <div class="filter alphabet" :style="'order:' + alphaFilterOrder + ';'">
        <AlphaFilter :alphabetFilters="alphabetFilters" />
      </div>
    </div>
    <div v-if="taxonomyFilters.length" class="clear">
      <Button class="action" label="Reset" @click="clearAllFilters()" />
    </div>
  </div>
</template>

<script>
import AlphaFilter from "./AlphaFilter.vue"
import TaxonomyFilter from "./TaxonomyFilter.vue"
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
  components: {InputText, Button, TaxonomyFilter, AlphaFilter},
  props:      {
    items:              {
      type:    Array,
      default: []
    },
    taxonomyFilterData: {
      type:    Array,
      default: []
    },
    sidebarBreakpoint:  {
      type:    Number,
      default: 576
    },
    taxonomyFilterOrders: {
      type:    Array,
      default: []
    },
    alphaFilterOrder: {
      type:    Number,
      default: 1
    },
  },

  data() {
    return {
      alphaSearch:     null,
      textSearch:      "",
      isMenuOpen:      null,
      windowWidth:     null,
      prevWindowWidth: null,
      taxonomyFilters: this.taxonomyFilterData,
      alphabetFilters: [
        {letter: "a", count: 0},
        {letter: "b", count: 0},
        {letter: "c", count: 0},
        {letter: "d", count: 0},
        {letter: "e", count: 0},
        {letter: "f", count: 0},
        {letter: "g", count: 0},
        {letter: "h", count: 0},
        {letter: "i", count: 0},
        {letter: "j", count: 0},
        {letter: "k", count: 0},
        {letter: "l", count: 0},
        {letter: "m", count: 0},
        {letter: "n", count: 0},
        {letter: "o", count: 0},
        {letter: "p", count: 0},
        {letter: "q", count: 0},
        {letter: "r", count: 0},
        {letter: "s", count: 0},
        {letter: "t", count: 0},
        {letter: "u", count: 0},
        {letter: "v", count: 0},
        {letter: "w", count: 0},
        {letter: "x", count: 0},
        {letter: "y", count: 0},
        {letter: "z", count: 0}
      ],
    };
  },
  created() {
    this.windowWidth = window.innerWidth;
    this.isMenuOpen = this.windowWidth >= this.sidebarBreakpoint;
    window.addEventListener("resize", this.handleViewportResize);
  },
  mounted() {
    // console.log(this.taxonomyFilters);
    this.calculateAlphaFilterItemCounts(this.items);
  },
  methods: {
    handleViewportResize(event) {
      this.prevWindowWidth = this.windowWidth;
      this.windowWidth = event.target.innerWidth;

      // Ensure filters are expanded when exceeding breakpoint
      if (this.windowWidth >= this.sidebarBreakpoint) {
        this.isMenuOpen = true;
      }

      // Ensure filters are collapsed when moving from exceeding breakpoint to below
      if ((this.windowWidth < this.sidebarBreakpoint) && (this.prevWindowWidth >= this.sidebarBreakpoint)) {
        this.isMenuOpen = false;
      }

    },
    toggleFilterControls() {
      console.log('toggling filter controls');
      this.isMenuOpen = !this.isMenuOpen;
    },
    didUserPressEnter(event) {
      if (event.key === 'Enter' || event.keyCode === 13) {
        this.setSearchInputFilterTerms();
      }
    },
    setSearchInputFilterTerms() {
      let terms = [];
      let input = this.textSearch;

      // Find occurrences of text wrapped in double quotes
      const regexp = /"(.*?)"/g;
      const multiWordTerms = input.match(regexp);
      if (multiWordTerms) {
        multiWordTerms.map((term) => {
          // remove the term, including its wrapping quotes from the input string
          input = input.replace(term, "");

          // remove the wrapping quotes from around the term
          term = term.replaceAll('"', '');

          terms.push(term.toLowerCase());
        });
      }

      // Split remaining terms in string into array and push
      input = input.trim();
      input = input.replace("  ", " ");

      // if anything is remaining, break out each word into a single term to search for
      if (input) {
        let singleWordTerms = input.split(" ");
        singleWordTerms.map((term) => {
          terms.push(term.toLowerCase());
        });
      }

      this.$parent.searchTerms = terms;

      // console.log("Search terms: ");
      // console.log(this.$parent.searchTerms);
      this.filterItems();
    },
    filterByTaxonomyTerms(source) {
      // console.log('filterByTaxonomyTerms')
      let taxFilterResults = [];

      // loop over each of the items to filter
      for (let itemIndex in source) {
        let item = source[itemIndex];
        item.includeInResults = true;
        // loop over the taxonomies in the taxonomyFilters
        // console.log('this.$parent.taxonomyFilterTerms')
        // console.log(this.$parent.taxonomyFilterTerms)
        for (let taxonomy in this.$parent.taxonomyFilterTerms) {
          // console.log('let taxonomy in this.$parent.taxonomyFilterTerms')
          // console.log(taxonomy)
          // if there are terms to be filtered in the current taxonomyFilter
          if (this.$parent.taxonomyFilterTerms[taxonomy].length) {
            // loop over each of the terms
            for (let termIndex in this.$parent.taxonomyFilterTerms[taxonomy]) {
              // if the item does not contain the taxonomy term, exclude it from results.
              let term = this.$parent.taxonomyFilterTerms[taxonomy][termIndex];
              if (!item.taxonomies[taxonomy].terms.includes(term)) {
                item.includeInResults = false;
              }
            }
          }
        }
        if (item.includeInResults) {
          taxFilterResults.push(item);
        }
      }

      return taxFilterResults;
    },
    filterItemsBySearchInput(source) {
      // console.log('filtering via text input on the following items: ');
      // console.log(source);
      let searchFilterResults = [];

      if (this.$parent.searchTerms.length) {
        // loop over all available items
        for (let itemIndex in source) {
          let item = source[itemIndex];
          item.containsAllSearchTerms = true;

          for (let term in this.$parent.searchTerms) {
            let titleContainsTerm = false;
            let descContainsTerm = false;
            // Search the Title for the search term
            let title = item.title.toLowerCase();
            if (title.includes(this.$parent.searchTerms[term])) {
              titleContainsTerm = true;
            }

            // Search the Description for the search term
            let description = item.description;
            if (description.includes(this.$parent.searchTerms[term])) {
              descContainsTerm = true;
            }
            if (titleContainsTerm || descContainsTerm) {
              //something
            } else {
              item.containsAllSearchTerms = false;
            }
          }

          // if the item meets the criteria, push it to results
          if (item.containsAllSearchTerms) {
            searchFilterResults.push(item);
          }

        }
      } else {
        searchFilterResults = source;
      }

      return searchFilterResults;
    },
    filterItemsByAlphabet(source) {
      let alphaFilterResults = source;
      // console.log(source);
      if (this.alphaSearch) {
        alphaFilterResults = [];
        for (let i = 0; i < source.length; i++) {
          let title = source[i].title.toLowerCase()
          if (title.startsWith(this.alphaSearch)) {
            alphaFilterResults.push(source[i]);
          }
        }
      }

      return alphaFilterResults;
    },
    filterItems() {
      let results = [];

      results = this.filterByTaxonomyTerms(this.items);
      results = this.filterItemsBySearchInput(results);

      // get item counts for Alphabet before filtering otherwise they are all 0 except the selected value.
      this.calculateAlphaFilterItemCounts(results)
      results = this.filterItemsByAlphabet(results);

      this.calculateTaxonomyTermItemCounts(results);

      // this.$parent.filteredItems = null;
      this.$parent.filteredItems = results;
      // this.$parent.paginatedItems = results;

      // console.log('Filtered Results:')
      // console.log(this.$parent.filteredItems)
    },
    calculateTaxonomyTermItemCounts(source) {

      // Iterate over the filters
      let taxonomyFilters = this.taxonomyFilters;
      for (let f = 0; f < taxonomyFilters.length; f++) {
        // console.log(taxonomyFilters[f])

        // iterate over the terms inside each filter
        for (let t = 0; t < taxonomyFilters[f].terms.length; t++) {

          this.taxonomyFilters[f].terms[t].count = 0;

          let currentFilterTerm = taxonomyFilters[f].terms[t];
          // console.log(currentFilterTerm)

          // iterate over the search results
          for (let s = 0; s < source.length; s++) {

            let itemTaxonomyTerms = source[s].taxonomies[taxonomyFilters[f].plural_name].terms;
            // console.log(itemTaxonomyTerms)

            // check to see if the filter term is in the items terms
            if (itemTaxonomyTerms.includes(currentFilterTerm.slug)) {
              // console.log('included')
              this.taxonomyFilters[f].terms[t].count = taxonomyFilters[f].terms[t].count + 1;
            }
          }
        }
      }
      this.taxonomyFilters = taxonomyFilters;
    },
    calculateAlphaFilterItemCounts(source) {

      // Iterate through each letter
      for (let a = 0; a < this.alphabetFilters.length; a++) {
        let count = 0;

        // iterate through the results
        for (let s = 0; s < source.length; s++) {

          // if the title starts with the letter, increase the count
          let title = source[s].title.toLowerCase()
          if (title.startsWith(this.alphabetFilters[a].letter)) {
            count++;
          }
        }
        this.alphabetFilters[a].count = count;
      }
    },
    clearAllFilters() {
      this.$parent.searchTerms = [];
      this.textSearch = "";
      this.$parent.taxonomyFilterTerms = this.$parent.initializeTaxonomyFilterTerms();
      this.calculateTaxonomyTermItemCounts(this.items)
      this.calculateAlphaFilterItemCounts(this.items)
      this.taxonomyFilters = [];
      setTimeout(() => {
        this.taxonomyFilters = this.taxonomyFilterData;
      }, 5)

      // this.$parent.filteredItems = null;
      this.$parent.filteredItems = this.items;
      // this.$parent.paginatedItems = this.items;
    }
  }
}
</script>
