<template>
    <div class="card" :class="cardClasses" @mouseenter="toggleButtonStyles(true)"
         @mouseleave="toggleButtonStyles(false)">
        <div class="absolute-elements">
            <div v-if="displayTaxonomyTerm()" class="taxonomy-term" v-html="card.taxonomyTerm"></div>
        </div>
        <component :is="href ? 'a' : 'span'" class="inner-wrapper" :class="wrapperClasses" :href="href"
                   :style="getStyle()">
            <div v-if="type === 'z-card'" class="background-overlay" :style="getZcardOverlayStyle()"></div>
            <div v-if="displayImage() || displaySVG()" class="block image">
                <figure v-if="displayImage()" v-html="card.imageTag">
                </figure>
                <div class="svg-wrapper" v-if="displaySVG()" v-html="card.svg"></div>
            </div>
            <div v-if="displayContent()" class="block content">
                <div v-if="displayCardHeader()" class="card__header">
                    <card-title :titleLine1="card.titleLine1" :titleLine2="card.titleLine2"
                                :titleTag="card.titleTag"></card-title>
                </div>
                <div v-if="displayCardBody()" class="card__body" v-html="card.body"></div>
                <div v-if="displayPostMeta()" class="card__meta">
                    <span class="author">{{ card.postMeta.author }}</span>
                    <span class="date">{{ card.postMeta.date }}</span>
                </div>
                <div v-if="card.price" class="card__body">
                    <p class="price">${{ card.price }}</p>
                </div>
                <div v-if="displayCardFooter()" class="card__footer">
                    <button ref="button" class="button card__cta" :class="ctaClasses"
                            v-text="card.ctaText"></button>
                </div>
            </div>
        </component>

        <div v-if="displayProductCardFooter()" class="card__footer--product">
            <a v-if="!isSimpleProduct" ref="button" class="button card__cta" :class="ctaClasses" :href="href"
               v-text="card.ctaText"></a>
            <button v-if="isSimpleProduct" ref="AddToCartButton" class="button card__cta add-to-cart"
                    :class="ctaClasses" @click="addToCart()">
                Add to cart
            </button>
            <span ref="addToCartMessage" class="add-to-cart-message">{{ addToCartMessage }}</span>
            <span ref="addToCartErrorMessage" class="add-to-cart-error-message">{{ addToCartErrorMessage }}</span>
            <span ref="loading" class="loading"></span>
        </div>
    </div>
</template>

<script>
import CardTitle from "./CardTitle";

export default {
    components: {CardTitle},
    props:      {
        card: {
            props:   {
                type:            {
                    type: String,
                },
                slug:            {
                    type: String,
                },
                source:          {
                    type: String,
                },
                templateClasses: {
                    type: Array
                },
                groupClasses:    {
                    type: Array
                },
                themeClasses:    {
                    type: Array
                },
                hoverEffects:    {
                    type: Array
                },
                url:             {
                    type: String
                },
                mediaType:       {
                    type: String
                },
                svg:             {
                    type: String
                },
                imageTag:        {
                    type: String
                },
                imagePath:       {
                    type: String
                },
                imageAlt:        {
                    type: String,
                },
                imageClass:      {
                    type: String,
                },
                bgColor:         {
                    type: String,
                },
                titleTag:        {
                    type: String
                },
                titleLine1:      {
                    type: String,
                },
                titleLine2:      {
                    type: String
                },
                body:            {
                    type: String
                },
                taxonomyTerm:    {
                    type: String
                },
                price:           {
                    type: String
                },
                postMeta:        {
                    type: Array
                },
                displayPostMeta: {
                    type: Boolean
                },
                displayTaxTerm:  {
                    type: Boolean
                },
                displayTitle:    {
                    type: Boolean
                },
                displayBody:     {
                    type: Boolean
                },
                excerptLength:   {
                    type: Number
                },
                displayCta:      {
                    type: Boolean
                },
                ctaTheme:        {
                    type: String
                },
                ctaClass:        {
                    type: String
                },
                ctaText:         {
                    type: String
                },
                productData:     {
                    type: Array
                }
            },
            default: false
        }
    },
    data() {
        return {
            addToCartMessage:      'Added to cart :)',
            addToCartErrorMessage: 'Add to cart failed :(',
        };
    },
    mounted() {
        // console.log(this.card);
    },
    computed: {
        type:               function () {
            return this.card.type ? this.card.type : 'y-card';
        },
        href:               function () {
            return this.card.url ? this.card.url : ""
        },
        templateClasses:    function () {
            if (this.card.templateClasses) {
                return this.card.templateClasses.join(' ');
            }
            return '';
        },
        groupClasses:       function () {
            if (this.card.groupClasses) {
                return this.card.groupClasses.join(' ');
            }
            return '';
        },
        themeClasses:       function () {
            if (this.card.themeClasses) {
                return this.card.themeClasses.join(' ');
            }
            return '';
        },
        hoverEffectClasses: function () {
            if (this.card.hoverEffects) {
                return this.card.hoverEffects.join(' ');
            }
            return '';
        },
        cardClassList:      function () {
            let classList = [];
            classList.push(this.card.type);
            classList.push(this.card.slug);
            classList.push(this.card.cardClasses);
            classList.push(this.templateClasses);
            classList.push(this.groupClasses);
            classList.push(this.themeClasses);

            if (this.card.url) {
                classList.push(this.hoverEffectClasses);
            }

            if (this.displayTaxonomyTerm()) {
                classList.push('has-absolute-elements');
            }

            return classList;
        },
        wrapperClassList:   function () {
            let classList = [];
            classList.push(this.card.imageClass);
            if (this.card.url) {
                classList.push('a');
            }

            return classList;
        },
        ctaClassList:       function () {
            let classList = [];
            classList.push(this.card.ctaTheme);
            classList.push(this.card.ctaClass);

            return classList;
        },
        cardClasses:        function () {
            return this.cardClassList.join(' ');
        },
        wrapperClasses:     function () {
            return this.wrapperClassList.join(' ');
        },
        ctaClasses:         function () {
            return this.ctaClassList.join(' ');
        },
        isSimpleProduct:    function () {
            return this.card.productData.type === 'simple';
        }
    },
    methods:  {
        getStyle:                 function () {
            let style = '';
            if (this.card.imagePath && this.type === 'z-card') {
                style += 'background-image: url(' + this.card.imagePath + ');';
            } else if (this.card.bgColor && this.type !== 'z-card') {
                style += 'background-color:' + this.card.bgColor + ';';
            }

            return style;
        },
        getZcardOverlayStyle:     function () {
            let style = '';

            if (this.card.bgColor && this.type === 'z-card') {
                style += 'background-color:' + this.card.bgColor + ';';
            }

            return style;
        },
        displayImage:             function () {
            return (this.card.mediaType === "img" && (this.card.imagePath || this.card.imageTag) && (this.type === 'y-card' || this.type === 'x-card'));
        },
        displaySVG:               function () {
            return (this.card.mediaType === "svg" && this.card.svg && (this.type === 'y-card' || this.type === 'x-card'));
        },
        displayContent:           function () {
            return this.card.titleLine1 || this.card.titleLine2 || this.card.body || (this.card.ctaText && this.card.href);
        },
        displayCardHeader:        function () {
            return this.card.displayTitle && (this.card.titleLine1 || this.card.titleLine2);
        },
        displayPostMeta:          function () {
            return this.card.displayPostMeta && this.card.postMeta.hasOwnProperty('author');
        },
        displayTaxonomyTerm:      function () {
            return Boolean(this.card.displayTaxTerm && this.card.taxonomyTerm);
        },
        displayCardBody:          function () {
            return this.card.displayBody && this.card.body;
        },
        displayCardFooter:        function () {
            return (this.card.source !== 'product') && this.card.displayCta && this.card.ctaText && this.card.url;
        },
        displayProductCardFooter: function () {
            return (this.card.source === 'product') && this.card.displayCta && this.card.ctaText && this.card.url;
        },
        toggleButtonStyles:       function (add) {
            if (this.$refs.button) {
                if (add) {
                    this.$refs.button.classList.add('hover');
                } else {
                    this.$refs.button.classList.remove('hover');
                }
            }
        },
        addToCart:                function () {
            this.$refs.AddToCartButton.classList.add('inactive');
            this.$refs.loading.classList.add('active');
            let quantity = 1;

            fetch("/?add-to-cart=" + this.card.productData.id + "&quantity=" + quantity, {method: 'get'})
                .then(() => {
                    this.$refs.loading.classList.remove('active');
                    if (this.$refs.addToCartMessage) {
                        this.$refs.addToCartMessage.classList.add('active');
                    }
                    console.log(quantity + ' item(s) added to cart');

                    // Update cart-item-count in header
                    let cartItemCountEl = document.getElementById('cart-item-count');
                    let cartItemCount = cartItemCountEl.getAttribute('data-cart-item-count')
                    cartItemCountEl.innerHTML = parseFloat(cartItemCount) + parseFloat(quantity);
                    console.log('cart-item-count updated');
                })
                .catch(err => {
                    this.$refs.loading.classList.remove('active');
                    if (this.$refs.addToCartErrorMessage) {
                        this.$refs.addToCartErrorMessage.classList.add('active');
                    }
                    console.log(err);
                });

        }
    }
}
</script>
