<template>
  <div class="module contact-details">
    <div class="group">
      <h3 class="group-title">Address</h3>
      <span class="detail">{{ contact.address_1 }}</span><br>
      <span class="detail" v-if="contact.address_2">{{ contact.address_2 }}</span><br>
      <span class="detail">{{ contact.city }}, {{ contact.state }} {{ contact.postal }}</span><br>
      <span class="detail">{{ contact.country }}</span>
    </div>
    <div class="group">
      <h3 class="group-title">Phone & Email</h3>
      <label>Phone: </label><span class="detail">{{ contact.phone }}</span><br>
      <label>Email: </label><span class="detail">{{ contact.email }}</span>
    </div>
    <div class="group">
      <h3 class="group-title">Employment</h3>
      <label>Employer: </label><span class="detail">{{ contact.employer }}</span><br>
      <label>Position: </label><span class="detail">{{ contact.position }}</span><br>
      <label>Income: </label><span class="detail">{{ contact.income }}</span>
    </div>
    <div class="group">
      <h3 class="group-title">Personal</h3>
      <label>Birthday: </label><span class="detail">{{ formatDate(contact.birthday) }}</span><br>
      <label>Relationship: </label><span class="detail">{{ contact.relationship_status }}</span>
    </div>
    <div class="group">
      <h3 class="group-title">Meta</h3>
      <label>Type: </label><span class="detail">{{ contact.type }}</span><br>
      <label>Archived: </label><span class="detail">{{ contact.is_archive ? "Yes" : "No" }}</span><br>
      <label>Created: </label><span class="detail">{{ formatDate(contact.created_at) }}</span><br>
      <label>Modified: </label><span class="detail">{{ formatDate(contact.modified_at) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: {
      type:    Object,
      default: {}
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-us', {
        weekday: "long", year: "numeric", month: "short", day: "numeric"
      })
    }
  }
}
</script>