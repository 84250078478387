<template>
    <div class="carousel__control left"  :class="arrowsClass" data-glide-el="controls">
        <div class="carousel__arrow carousel__arrow--left" data-glide-dir="<">
            <a href="javascript:void(0)" aria-label="carousel left arrow control" class="arrow" :class="arrowBgColorClass">
                <component :class="'carousel__arrow-icon ' + arrowFillClass" :is="iconComponentLeft"></component>
            </a>
        </div>
    </div>
    <div class="carousel__control right"  :class="arrowsClass" data-glide-el="controls">
        <div class="carousel__arrow carousel__arrow--right" data-glide-dir=">">
            <a href="javascript:void(0)" aria-label="carousel right arrow control" class="arrow" :class="arrowBgColorClass">
                <component :class="'carousel__arrow-icon ' + arrowFillClass" :is="iconComponentRight"></component>
            </a>
        </div>
    </div>
<!--    </div>-->
</template>

<script>
import {ref} from 'vue';
import useSvgSprite from "../../../composables/use-svg-sprite";

export default {
    props: {
        iconLeft:   {
            type:    String,
            default: ''
        },
        iconRight:  {
            type:    String,
            default: ''
        },
        arrowBgColorClass: {
            type:    String,
            default: ''
        },
        arrowFillClass: {
            type:    String,
            default: ''
        },
        arrowsClass: {
            type:    String,
            default: ''
        }
    },

    setup(props) {
        // pull in the svg-sprite composable
        const {getSvg} = useSvgSprite(props);

        const iconComponentLeft = ref(null);
        const iconComponentRight = ref(null);

        // get the left & right SVGs
        iconComponentLeft.value = getSvg(props.iconLeft ? props.iconLeft : "chevron-left");
        iconComponentRight.value = getSvg(props.iconRight ? props.iconRight : "chevron-right");

        return {
            iconComponentLeft,
            iconComponentRight,
        };
    },

    mounted() {
    }
}
</script>
