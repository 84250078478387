<template>
        <a :href="item.url" target="_blank" class="card" :class="cardSettings.card_image_display_type">

            <div class="background-overlay" :style="cardSettings.styles.bg_color"></div>

            <div class="block image" v-if="cardSettings.card_image_display" >
                <figure v-html="item.image_data.tag"></figure>
            </div>
            <div class="block content">
                <div class="card__header">
                    <h3 class="h5 card__title" v-html="markWordsInSearch(item.title)"
                        :style="cardSettings.styles.title"></h3>
                </div>
                <div class="card__body" :style="cardSettings.styles.body">
                    <p v-html="markWordsInSearch(item.description)"></p>
                    <CardTaxonomies
                        :taxonomies="item.taxonomies"
                        :cardSettings="cardSettings">
                    </CardTaxonomies>
                </div>
            </div>
        </a>
</template>

<script>
import CardTaxonomies from "./CardTaxonomies.vue";

export default {
    components: {CardTaxonomies},
    props:      {
        item:         {
            type:    Object,
            default: {}
        },
        cardSettings: {
            type:    Object,
            default: {}
        },
    },
    methods:    {
        hasWhiteSpace(string) {
            return (/\s/).test(string);
        },
        markWordsInSearch(text) {
            let searchTerms = this.$parent.$parent.searchTerms;
            // First look for grouped search terms and <mark> them, this must be done first,
            for (let i in searchTerms) {
                let searchTerms = this.$parent.$parent.searchTerms;
                if (this.hasWhiteSpace(searchTerms[i])) {
                    let regex = new RegExp(searchTerms, "gi")
                    text = text.replace(regex, "<mark>" + searchTerms[i] + "</mark>");
                }
            }

            // Then look for the single search terms and <mark> them
            let formattedText = []
            let words = text.split(' ');
            for (let i in words) {
                let word = words[i].toLowerCase().replace(/\.$/, "")
                if (searchTerms.includes(word)) {
                    formattedText.push('<mark>' + words[i] + '</mark>');
                } else {
                    formattedText.push(words[i]);
                }
            }
            formattedText = formattedText.join(' ')

            return formattedText;
        }
    }
}
</script>
