<template>
  <div :class="taxonomy.plural_name">
    <TaxonomyLabel :taxonomy="taxonomy"></TaxonomyLabel>
    <TaxonomyTerms :taxonomy="taxonomy"></TaxonomyTerms>
  </div>
</template>

<script>
import TaxonomyLabel from "./TaxonomyLabel.vue"
import TaxonomyTerms from "./TaxonomyTerms.vue"
export default {
  components: {TaxonomyLabel, TaxonomyTerms},
  props:   {
    taxonomy: {
      type:    Object,
      default: {}
    }
  },
  methods: {
    isFilterTermActive(term, taxonomy) {
      let taxPluralName = taxonomy.plural_name;
      return this.$parent.$parent.$parent.$parent.taxonomyFilterTerms[taxPluralName].includes(term);
    }
  }
}
</script>