<template>
    <span
        class="term"
        :class="[
            {active: isFilterTermActive(term, taxonomy)},
            $parent.$parent.$parent.$parent.$parent.cardSettings.taxonomies[taxonomy.plural_name].style
            ]"
        v-for="term in taxonomy.terms">
            {{ term }}
    </span>
</template>

<script>
export default {
  props:   {
    taxonomy: {
      type:    Object,
      default: {}
    }
  },
  methods: {
    isFilterTermActive(term, taxonomy) {
      let taxPluralName = taxonomy.plural_name;
      return this.$parent.$parent.$parent.$parent.$parent.taxonomyFilterTerms[taxPluralName].includes(term);
    }
  }
}
</script>