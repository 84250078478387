<template>
        <div v-for="taxonomy in taxonomies" class="taxonomy-terms">
          <CardTaxonomy
              v-if="parseInt(this.cardSettings.taxonomies[taxonomy.plural_name].display)"
              :taxonomy="taxonomy"
          ></CardTaxonomy>
        </div>
</template>

<script>
import CardTaxonomy from "./CardTaxonomy.vue";

export default {
  components: {CardTaxonomy},
  props: {
    taxonomies: {
      type:    Object,
      default: {}
    },
    cardSettings: {
      type:    Object,
      default: {}
    },
  }
}
</script>