<template>
  <div class="personal-bests-index">

    <personal-best-form
        :personal-bests="personalBests"
        :parent-options="personalBestParents.input_options"
        @personalBestCreated="addPersonalBest($event)">
    </personal-best-form>

    <table v-if="personalBests">
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Title</th>
        <th>Description</th>
        <th>Measurement</th>
        <th>Record</th>
        <th>Update</th>
        <th>Delete</th>
      </tr>
      <personal-best v-for="(item, index) in personalBests"
                     :key="item.ID"
                     :index="index"
                     :personal-best="item"
                     :personal-best-parents="personalBestParents"
                     @personalBestDestroyed="removePersonalBest($event)">
      </personal-best>

    </table>

  </div>
</template>

<script>
import PersonalBest from './PersonalBest';
import PersonalBestForm from "./PersonalBestForm";

export default {
  components: {PersonalBest, PersonalBestForm},
  props: {
    personalBests: {
      type: Array,
    },
    personalBestParents: {
      type: Object,
    }
  },

  data() {
    return {
      placeholder: {},
    };
  },
  mounted() {
    // console.log("personal-bests vue component mounted!");
    // console.log('personalBests Array:')
    // console.log(this.personalBests);
  },
  methods: {
    getPersonalBestByID(ID) {
      for (const pb of this.personalBests) {
        if (parseInt(pb.ID) === parseInt(ID)) {
          return pb;
        }
      }
    },
    addPersonalBest(object) {
      console.log('Updating personalBests with new Object');
      console.log(object);
      this.personalBests.push(object);
      this.$forceUpdate();
    },
    removePersonalBest(object) {
      console.log('Removing personalBest:');
      console.log(object);
      this.personalBests.splice(object.index, 1); // not working, keeps deleting last one.
      this.$forceUpdate();
    }
  }
}
</script>