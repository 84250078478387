<template>
  <form class="module contact-editor">
    <div class="module-body">
      <fieldset class="flex-cols gap-10 flex-cols-2">
        <InputText v-model="contact.first_name" placeholder="first name" required />
        <InputText v-model="contact.last_name"  placeholder="last name" />
      </fieldset>
      <fieldset class="flex-cols gap-10 flex-cols-2">
        <InputText v-model="contact.address_1"  placeholder="address line 1" />
        <InputText v-model="contact.address_2" placeholder="address line 2" />
      </fieldset>
      <fieldset class="flex-cols gap-10 flex-cols-4">
        <InputText v-model="contact.city" placeholder="city"/>
        <InputText v-model="contact.state" placeholder="state"/>
        <InputText v-model="contact.postal" placeholder="postal"/>
        <InputText v-model="contact.country" placeholder="country"/>
      </fieldset>
      <fieldset class="flex-cols gap-10 flex-cols-2">
        <InputMask v-model="contact.phone" mask="999.999.9999" placeholder="phone"/>
        <InputText v-model="contact.email" placeholder="email"/>
      </fieldset>
      <fieldset class="flex-cols gap-10 flex-cols-3">
        <InputText v-model="contact.employer" placeholder="employer"/>
        <InputText v-model="contact.position" placeholder="position"/>
        <InputNumber v-model="contact.income" mode="currency" currency="USD" locale="en-US" :maxFractionDigits="0" placeholder="income"/>
      </fieldset>
      <fieldset class="flex-cols gap-10 flex-cols-2">
        <Calendar v-model="contact.birthday" dateFormat="yy-mm-dd" placeholder="birthday"/>
        <Dropdown v-model="contact.relationship_status"
                  :options="[{label: 'Single', value: 'single'}, {label: 'married', value: 'Married'}]"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Marital Status" />
      </fieldset>
      <fieldset class="flex-cols gap-10 flex-cols-2">
        <Dropdown v-model="contact.type"
                  :options="[{label: 'Prospect', value: 'prospect'}, {label: 'Client', value: 'client'}]"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Contact Type" />
        <Dropdown v-model="contact.is_archive"
                  :options="[{label: 'Yes', value: '1'}, {label: 'No', value: '0'}]"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Archive?" />
      </fieldset>
    </div>
    <footer class="module-footer">
      <Button type="submit" v-if="isNewContactForm" @click.prevent="createContact"
              :disabled="!newContactValidated" label="Add" />
      <Button v-if="!isNewContactForm" @click="updateContact" size="small" label="Update" />
      <Button v-if="!isNewContactForm" @click="deleteContact" size="small"
              severity="danger" label="Delete" />
    </footer>
  </form>
</template>

<script>
import qs from "qs";
import axios from "axios";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import InputNumber from "primevue/inputnumber";
import InputMask from 'primevue/inputmask';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';

export default {
  components: {
    Button,
    InputText,
    InputNumber,
    InputMask,
    Dropdown,
    Calendar
  },
  props:      {
    contact: {
      type:    Object,
      default: {}
    },
    isNewContactForm: {
      type:    Boolean,
      default: false
    }
  },
  computed: {
    newContactValidated() {
      return this.contact.type && this.contact.first_name && this.contact.last_name && this.contact.is_archive;
    }
  },
  methods:  {
    createContact() {
      let data = {
        action: "create_fc_" + "mc_contacts",
      };

      for (const [key, value] of Object.entries(this.contact)) {
        data[key] = value
      }
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            console.log(response.data)
            if (response.data.result) {
              this.contact.ID = response.data.object_id
              this.$emit('contactCreated',this.contact);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    updateContact() {
      let data = {
        action: "update_fc_" + "mc_contacts",
      };
      for (const [key, value] of Object.entries(this.contact)) {
        data[key] = value
      }
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            // console.log(response.data)
            this.$emit('contactUpdated',this.contact);
          })
          .catch((error) => {
            console.log(error)
          });

    },
    deleteContact() {
      let data = {
        action: "destroy_fc_" + "mc_contacts",
        data:   this.contact,
      };
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            // console.log(response.data)
            if (response.data.result) {
              this.$emit('contactDeleted', this.contact);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    }
  }
}
</script>