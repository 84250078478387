(function () {
    if (location.hash) {
        console.log(location.hash)
        let target = location.hash;

        setTimeout(function () {
            let siteHeaderHeight =  document.querySelector('.site-header').offsetHeight;
            // console.log(siteHeaderHeight)
            let anchorPos = document.querySelector(target).offsetTop;
            // console.log(anchorPos)
            window.scrollTo(0, anchorPos);
        },100);
    }
})();
