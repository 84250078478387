<template>
  <!-- Single Select Inputs -->
  <h3 class="h6 strong">Alphabetically</h3>
  <div class="multi-checkbox-wrapper">
    <div class="checkbox">
      <RadioButton
          v-model="selectAll"
          @change="handleAllOptionChange"
          :binary="true"
      />
      <label :for="selectAll">All</label>
    </div>
    <div class="toggle-area" :class="{ open: filterExpanded }">
      <div
          v-for="filter in alphabetFilters"
          :key="filter.letter"
          class="radio">
        <RadioButton
            v-model="filterLetter"
            :inputId="filter.letter"
            :name="filter.letter"
            :value="filter.letter"
            :disabled="!filter.count"
            @change="setAlphaFilter(filter.letter); setHandleAllOptionFalse();"
        />
        <label :for="filter.letter" :class="{disabled: !filter.count}">{{ filter.letter.toUpperCase() }}</label>
        <span class="count" :class="{disabled: !filter.count}">{{ filter.count }}</span>
      </div>
    </div>
    <span class="toggle-button" v-html="filterExpanded ? 'View Less' : 'View All'"
          @click="toggleFilterHeight"></span>
  </div>
</template>

<script>


import RadioButton from 'primevue/radiobutton';

export default {
  components: {RadioButton},
  props:      {
    alphabetFilters: {
      type:    Array,
      default: []
    }
  },
  data() {
    return {
      filterLetter:     null,
      selectAll: true,
      filterExpanded:   false
    };
  },
  mounted() {
    // console.log(this.taxonomy);
  },
  methods: {
    setHandleAllOptionFalse() {
      // console.log('setHandleAllOptionFalse ')
      this.selectAll = false;
    },
    handleAllOptionChange() {
      if (this.selectAll) {
        this.filterLetter = null;
        setTimeout(() => {
          this.setAlphaFilter(this.filterLetter)
        }, 5)
      }
    },
    toggleFilterHeight() {
      this.filterExpanded = !this.filterExpanded;
    },
    setAlphaFilter(letter) {
      this.$parent.alphaSearch = letter;
      this.$parent.filterItems();
    }
  }
}
</script>