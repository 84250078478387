import Accordion from "./accordion/Accordion";
import BookList from "./book-list/BookList";
import Cards from "./cards/Cards";
import Card from "./cards/Card";
import Carousel from "./carousel/Carousel";
import CollectionItems from "./collections/CollectionItems";
import CommunityMembers from "./community-members/CommunityMembers";
// import Lightbox from "./lightbox/Lightbox";
import LocationsGrid from "./locations/LocationsGrid.vue";
import LocationsOverview from "./locations/LocationsOverview.vue";
import NavList from "./nav-list/NavList";
import Search from "./search/Search";
import Tabs from "./Tabs/Tabs";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'fc-accordion': Accordion,
    'fc-book-list': BookList,
    'fc-carousel': Carousel,
    'fc-cards': Cards,
    'fc-card': Card,
    'fc-collection-items': CollectionItems,
    'fc-community-members': CommunityMembers,
    // 'fc-lightbox': Lightbox,
    'fc-locations-grid': LocationsGrid,
    'fc-locations-overview': LocationsOverview,
    'fc-nav-list': NavList,
    'fc-search': Search,
    'fc-tabs': Tabs,
};
