<template>
  <div v-if="exists" class="module note">
    <div class="module-header">
      <span>{{ formatDate(note.created_at) }}</span><Button size="small"  v-show="!editing" @click="editNote">Edit</Button>
    </div>
    <div class="module-body">
      <div v-show="!editing" v-html="note.content"></div>
      <Editor v-show="editing" v-model="note.content" editorStyle="height: 120px" />
    </div>
    <div class="module-footer">
      <Button size="small" v-show="editing" @click="updateNote(note)">Update</Button>
      <Button size="small" severity="danger"  v-show="editing" @click="deleteNote(note)">Delete</Button>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import axios from "axios";
import Button from "primevue/button";
import Editor from 'primevue/editor';

export default {
  components: {
    Button,
    Editor
  },
  props:      {
    note: {},
    parentObject: ''
  },
  data() {
    return {
      editing: false,
      exists: true
    };
  },
  methods:  {
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-us', {
        weekday: "long", year: "numeric", month: "short", day: "numeric"
      })
    },
    editNote() {
      this.editing = true;
      this.$emit('stateChange', {editing: true});
    },
    updateNote(note) {
      let data = {
        action: "update_fc_" + "mc_" + this.parentObject + "_notes",
        data:   note,
      };
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            // console.log(response.data)
            this.editing = false;
            this.$emit('stateChange', {editing: false});
          })
          .catch((error) => {
            console.log(error)
          });

    },
    deleteNote(note) {
      let data = {
        action: "destroy_fc_" + "mc_" + this.parentObject + "_notes",
        data:   note,
      };
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            // console.log(response.data)
            this.exists = false;
            this.$emit('stateChange', {editing: false});
          })
          .catch((error) => {
            console.log(error)
          });
    }
  }
}
</script>