<template>
  <div class="meals-index">
    <Accordion :activeIndex="0">
      <AccordionTab :header="formatDate(day.date)" v-if="days" v-for="day in days">

        <div class="sidebar">
          <h3 class="sidebar-title">At a glance</h3>

          <div class="data-set">
            <h4 class="data-title">Calories: {{ day.macro_data.calories.total }}</h4>
          </div>

          <div class="data-set">
            <h4 class="data-title">Fats</h4>
            <ul>
              <li>grams: {{ day.macro_data.macros.fat }}</li>
              <li>calories: {{ day.macro_data.calories.fat }}</li>
              <li>percent: {{ day.macro_data.percentages.fat }}%</li>
            </ul>
          </div>

          <div class="data-set">
            <h4 class="data-title">Carbohydrates</h4>
            <ul>
              <li>grams: {{ day.macro_data.macros.carb }}</li>
              <li>calories: {{ day.macro_data.calories.carb }}</li>
              <li>percent: {{ day.macro_data.percentages.carb }}%</li>
            </ul>
          </div>

          <div class="data-set">
            <h4 class="data-title">Protein</h4>
            <ul>
              <li>grams: {{ day.macro_data.macros.protein }}</li>
              <li>calories: {{ day.macro_data.calories.protein }}</li>
              <li>percent: {{ day.macro_data.percentages.protein }}%</li>
            </ul>
          </div>
        </div>

        <div class="content">
          <Accordion :activeIndex="0">
            <AccordionTab
                :header="meal.title + ' ' + meal.macro_data.calories.total +  ' calories'"
                :ref="meal.title" v-for="(meal, index) in day.meals"
            >
              <single-meal
                  :key="meal.ID"
                  :index="index"
                  :meal="meal"
                  @mealDestroyed="removeMeal($event)">
              </single-meal>
            </AccordionTab>
          </Accordion>
        </div>

      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import SingleMeal from './SingleMeal';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
  components: {SingleMeal, Accordion, AccordionTab},
  props:      {
    days: {
      type: Array,
    },
  },

  data() {
    return {
      placeholder: {},
    };
  },
  mounted() {
    // console.log("meals vue component mounted!");
    // console.log('days of meals:')
    // console.log(this.days);
  },
  methods: {
    formatDate(mealDate) {
      const date = new Date(mealDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate() + 1;

      return `${month}/${day}/${year}`;
    },
  }
}
</script>