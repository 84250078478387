<template>
    <div class="ui-wrapper">


        <div class="sidebar">
            <select v-model="selectedLocation" class="location-selector">
                <option selected disabled>Select a location</option>
                <option v-for="(location, index) in locations" :value="index">{{ location.post_title }}</option>
            </select>
            <div class="time-blocks">
                <div v-for="timeBlock in locations[selectedLocation].hours" class="time-block">
                    <span class="day">{{ timeBlock.day }}</span>
                    <span class="hours">{{ timeBlock.hours }}</span>
                </div>
            </div>
            <div class="primary-phone">
                <span class="label">Phone Number</span>
                <span class="phone-number">{{ locations[selectedLocation].phone_number }}</span>
            </div>
        </div>

        <div class="map-wrapper">
            <div class="map" v-for="(location, index) in locations">
<!--                <GoogleMap-->
<!--                    v-if="selectedLocation === index"-->
<!--                    :api-key="googleMapsAPIKey"-->
<!--                    :style="mapStyles"-->
<!--                    :center="{ lat: Number(location.geolocation.split(',')[0]), lng: Number(location.geolocation.split(',')[1]) }"-->
<!--                    :zoom="15"-->
<!--                >-->
<!--                    &lt;!&ndash;                <Marker v-for="location in locations" :options="{position: { lat: Number(location.geolocation.split(',')[0]), lng: Number(location.geolocation.split(',')[1]) } }" />&ndash;&gt;-->
<!--                    <Marker-->
<!--                        :options="{position: { lat: Number(location.geolocation.split(',')[0]), lng: Number(location.geolocation.split(',')[1]) }}" />-->
<!--                </GoogleMap>-->
                <iframe
                    v-if="selectedLocation === index"
                    :src="'https://www.google.com/maps/embed/v1/place?key=' + googleMapsAPIKey + '&q=' + get_location_map_query_param(index)"
                    :style="mapStyles"
                    allowfullscreen
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>

            </div>
        </div>


    </div>
</template>

<script>
import {GoogleMap, Marker} from 'vue3-google-map'
import themConfig from './../../../config.json';

const themeData = window[themConfig.theme_prefix + 'data'];

export default {
    components: {GoogleMap, Marker},
    props:      {
        locations: {
            type:    Array,
            default: []
        },
        mapStyles: {
            type:    String,
            default: ""
        }
    },

    data() {
        return {
            selectedLocation: 0,
            themeData:        window[themConfig.theme_prefix + 'data'],
            googleMapsAPIKey: themeData.google_maps_api_key
        }
    },
    mounted() {
        console.log(this.locations)
    },
    methods: {
        get_location_map_query_param(index) {
            let locationTitle = this.locations[index].post_title ?? "";
            let address1  = this.locations[index].address_1 ?? "";
            let city  = this.locations[index].city ?? "";
            let state  = this.locations[index].state ?? "";
            let postalCode  = this.locations[index].postal_code ?? "";

            let place =  locationTitle + (address1 ? "+" + address1 : "") + (city ? "+" + city : "") + (state ? "+" + state : "") + (postalCode ? "+" + postalCode : "");
            console.log(place);
            return place;
        }
    }
};
</script>
