<template>
  <!-- Single Select Inputs -->
  <h3 class="h6 strong">{{ taxonomy.name }}</h3>
  <Dropdown
      v-if="!parseInt(taxonomy.multi_select) && taxonomy.input_type === 'dropdown'"
      v-model="filters[index]"
      :options="getSelectInputOptions(taxonomy.terms)"
      optionLabel="term"
      :placeholder="'Select a ' + taxonomy.name"
      class=""
      showClear
      @change="setFilterTerms(filters[index], taxonomy)"
  />
  <div class="multi-radio-wrapper" v-if="!parseInt(taxonomy.multi_select) && taxonomy.input_type === 'radio'">
    <div class="radio">
      <RadioButton
          v-model="selectAll"
          @change="handleAllOptionChange"
          :binary="true"
      />
      <label :for="selectAll">All</label>
    </div>
    <div class="toggle-area" :class="{ open: filterExpanded }">
      <div
          v-for="term in taxonomy.terms"
          :key="term.slug"
          class="radio">
        <RadioButton
            v-model="filters[index]"
            :inputId="term.slug"
            :name="taxonomy.slug"
            :value="{term: term.slug}"
            :disabled="!term.count"
            @change="setFilterTerms(filters[index], taxonomy); setHandleAllOptionFalse();"
        />
        <label :for="term.slug" :class="{disabled: !term.count}">{{ term.title }}</label>
        <span class="count" :class="{disabled: !term.count}">{{ term.count }}</span>
      </div>
    </div>
    <span v-if="taxonomy.terms.length > 3" class="toggle-button" v-html="filterExpanded ? 'View Less' : 'View All'"
          @click="toggleFilterHeight"></span>
  </div>

  <!-- Multi Select Inputs -->
  <MultiSelect
      v-if="parseInt(taxonomy.multi_select) && taxonomy.input_type === 'dropdown'"
      v-model="filters[index]"
      :options="getSelectInputOptions(taxonomy.terms)"
      optionLabel="term"
      :placeholder="'Select ' + taxonomy.plural_name"
      :maxSelectedLabels="3"
      class=""
      @change="setFilterTerms(filters[index], taxonomy)"
  />
  <div class="multi-checkbox-wrapper" v-if="parseInt(taxonomy.multi_select) && taxonomy.input_type === 'checkbox'">
    <div class="checkbox">
      <Checkbox
          v-model="selectAll"
          @change="handleAllOptionChange"
          :binary="true"
      />
      <label :for="selectAll">All</label>
    </div>
    <div class="toggle-area" :class="{ open: filterExpanded }">
      <div
          v-for="term in taxonomy.terms"
          :key="term.slug"
          class="checkbox">
        <Checkbox
            v-model="filters[index]"
            :inputId="term.slug"
            :name="taxonomy.slug"
            :value="{term: term.slug}"
            :disabled="!term.count"
            @change="setFilterTerms(filters[index], taxonomy); setHandleAllOptionFalse();"
        />
        <label :for="term.slug" :class="{disabled: !term.count}">{{ term.title }}</label>
        <span class="count" :class="{disabled: !term.count}">{{ term.count }}</span>
      </div>
    </div>
    <span v-if="taxonomy.terms.length > 3" class="toggle-button" v-html="filterExpanded ? 'View Less' : 'View All'"
          @click="toggleFilterHeight"></span>
  </div>
</template>

<script>
import MultiSelect from "primevue/multiselect"
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';

export default {
  components: {MultiSelect, Dropdown, Checkbox, RadioButton},
  props:      {
    index:        {
      type:    Number,
      default: 0
    },
    taxonomyData: {
      type:    Object,
      default: {}
    },
  },
  data() {
    return {
      taxonomy:       this.taxonomyData,
      filters:        [],
      filterExpanded: false,
      selectAll:      true
    };
  },
  mounted() {
    // console.log(this.taxonomy);
  },
  methods: {
    setHandleAllOptionFalse() {
      // console.log('setHandleAllOptionFalse ')
      this.selectAll = false;
    },
    handleAllOptionChange() {
      // console.log('handleAllOptionChange')
      if (this.selectAll) {
        this.filters = [];
        setTimeout(() => {
          this.taxonomy = this.taxonomyData;
          this.setFilterTerms(this.filters, this.taxonomy)
        }, 5)
      }
    },
    toggleFilterHeight() {
      this.filterExpanded = !this.filterExpanded;
    },
    getSelectInputOptions(terms) {
      let options = [];
      for (let term in terms) {
        options.push({
          term: terms[term].slug
        })
      }

      return options;
    },
    setFilterTerms(filter, taxonomy) {
      // console.log('filter: ');
      // console.log(filter);
      let taxPluralName = taxonomy.plural_name;
      let taxonomyIsMultiSelect = Boolean(Number(taxonomy.multi_select));
      // console.log(taxonomyIsMultiSelect);
      // console.log(taxonomy);
      this.$parent.$parent.taxonomyFilterTerms[taxPluralName] = [];
      if (filter) {
        if (taxonomyIsMultiSelect) {
          for (let option in filter) {
            // console.log('Filter term ' + filter[option].term + ' added to ' + taxPluralName + ' taxonomy filter list');
            if(filter[option].hasOwnProperty("term")) {
              this.$parent.$parent.taxonomyFilterTerms[taxPluralName].push(filter[option].term);
            }
          }
        } else {
          if(filter.hasOwnProperty("term")) {
            // console.log('Filter term ' + filter.term + ' added to ' + taxPluralName + ' taxonomy filter list');
            this.$parent.$parent.taxonomyFilterTerms[taxPluralName].push(filter.term);
          }

        }
      }
      // console.log('Active term filters for: ' + taxPluralName);
      // console.log(this.$parent.taxonomyFilterTerms[taxPluralName]);

      this.$parent.filterItems();
    }
  }
}
</script>