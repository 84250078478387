<template>
  <div class="single-meal">

<!--    <h3>{{ title }} - {{ meal.macro_data.calories.total  }} Calories</h3>-->

    <div class="items flex-cols flex-cols-1 sm:flex-cols-2 md:flex-cols-3  justify-content-flex-start">
      <div class="item" v-for="item in formattedItems">
        <div class="item-header">
          <p class="title">{{ item.title }}{{ item.variety ? ' - ' + item.variety : ''}}{{ item.brand ? ' (' + item.brand + ')' : ''}}</p>
        </div>
        <div class="item-body">
          <ul>
            <li>Calories: {{ item.macro_data.calories.total }}</li>
            <li>Fat: {{ item.macro_data.calories.fat }}  ({{ item.macro_data.macros.fat }}g)</li>
            <li>Carb: {{ item.macro_data.calories.carb }}  ({{ item.macro_data.macros.carb }}g)</li>
            <li>Protein: {{ item.macro_data.calories.protein }}  ({{ item.macro_data.macros.protein }}g)</li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Datepicker from '@vuepic/vue-datepicker';
import {isProxy, toRaw} from 'vue';

export default {
  components: {Datepicker},
  props: {
    meal: {
      type: Object,
    },
    index: {
      type: Number,
    }
  },

  data() {
    return {
      objectId: this.meal.ID,
      title: this.meal.title,
      type: this.meal.type,
      formattedItems: this.meal.formatted_items,
      items: this.meal.items,
      mealDate: this.meal.meal_date,
    };
  },
  mounted() {
    // console.log("SingleMeal Vue-Component mounted!");
    // console.log(this.meal);
  },
  methods: {
  }
}
</script>