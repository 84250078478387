<template>
    <div class="tabs-wrapper" :class="currentOrientation">
        <div class="nav-wrapper">
            <div v-show="enableCarousel()" :id="carouselId" class="glide carousel">
                <slot name="track">
                    <div class="glide__track carousel__track" data-glide-el="track">
                        <ul class="glide__slides carousel__slides tabs">
                            <li v-for="(item, index) in items" class="tab" @click="toggleTab(index)"
                                :class="{current: currentTab === index}" :data-tab-index="index">
                                <span class="tab-title">{{ item.title }}</span>
                            </li>
                        </ul>
                    </div>
                </slot>

                <CarouselNavArrows
                    :iconLeft="iconLeft"
                    :iconRight="iconRight"
                    arrowsClass=""
                />
            </div>

            <ul v-show="!enableCarousel()" class="tabs">
                <li v-for="(item, index) in items" class="tab" @click="toggleTab(index)"
                    :class="{current: currentTab === index}">
                    <span class="tab-title">{{ item.title }}</span>
                </li>
            </ul>
        </div>

        <div class="content-wrapper">
            <slot></slot>
        </div>
    </div>

</template>

<script>
import {ref, reactive, computed} from 'vue';
import CarouselNavArrows from "./navigation/CarouselNavArrows";
import Glide from '@glidejs/glide';
import generateId from "../../composables/use-identifier";

const optionKeys = [
    'type',
    'startAt',
    'perView',
    'focusAt',
    'gap',
    'autoplay',
    'hoverpause',
    'keyboard',
    'bound',
    'swipeThreshold',
    'dragThreshold',
    'perTouch',
    'animationDuration',
    'rewind',
    'rewindDuration',
    'animationTimingFunc',
    'direction',
    'peek',
    'breakpoints',
    'throttle'
];

export default {
    name:       'Tabs',
    components: {CarouselNavArrows},
    props:      {
        items:               {
            default: {}
        },
        orientation:               {
            default: 'horizontal'
        },
        verticalBreakpoint:               {
            default: '0'
        },
        type:                {
            String,
            default: 'slider'
        },
        bound:               {
            Boolean,
            default: true
        },
        rewind:              {
            Boolean,
            default: false
        },
        animationTimingFunc: {
            String,
            default: 'ease-in-out'
        },
        gap:                 {
            type:    Number,
            default: 0
        },
        perView:             {
            type:    Number,
            default: 3
        },
        breakpoints:         {
            type:    Object,
            default: {}
        },
        options:             {
            type:    Object,
            default: {}
        },
        iconLeft:            {
            type:    String,
            default: ''
        },
        iconRight:           {
            type:    String,
            default: ''
        },
    },
    data() {
        return {
            currentTab:     0,
            loaded:         false,
            currentPerView: 1,
            tabContents:    null,
            currentOrientation: this.orientation,
        };
    },
    setup(props, {slots, attr, emit}) {
        const identifier = generateId();
        const mountElement = ref(null);
        const glide = ref(null);

        // create a unique carousel ID to prevent conflicts
        const carouselId = computed(() => {
            return `carousel-${identifier.value}`;
        });

        // set the element for mounting the carousel
        mountElement.value = `#${carouselId.value}`;

        // get the default carousel options
        let withOptions = {
            ...props.options
        };

        // set the carousel options specified via props
        for (const optionName of optionKeys) {
            if (props.hasOwnProperty(optionName) && props[optionName] !== undefined) {
                withOptions[optionName] = props[optionName];
            }
        }

        // declare the carousel options to use
        const carouselOptions = reactive(withOptions);

        // create the Glide instance
        glide.value = new Glide(mountElement.value, carouselOptions);

        return {
            carouselId,
            carouselOptions,
            glide
        };
    },
    computed: {
        isDisabled() {
            return this.glide.disabled;
        }
    },
    created() {
        window.addEventListener("resize", this.resizeHandler);
    },
    mounted() {
        window.addEventListener('load', ()=> {
            this.glide.mount();
        });

        this.loaded = true;

        this.$emit('mounted:carousel', this);

        let vueInstanceWrapper = this.$el.parentElement;
        vueInstanceWrapper.classList.remove('visually-hidden');

        this.currentPerView = this.glide._o.perView;

        this.tabContents = this.$el.querySelectorAll(".tab-content");


        // console.log(this.items);
        // console.log(this.orientation);
        // console.log(this.verticalBreakpoint);
    },
    unmounted() {
        this.glide.destroy();
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);
    },
    methods: {
        toggleTab: function (index) {
            this.currentTab = index;
            this.glide.index = index;
            // this.$forceUpdate();
            for (let i = 0; i < this.tabContents.length; i++) {

                let contentIndex = this.tabContents[i].getAttribute('data-tab-index');
                if (parseInt(contentIndex) === this.currentTab) {
                    this.tabContents[i].style.display = "block";
                } else {
                    this.tabContents[i].style.display = "none";
                }
            }
        },
        resizeHandler(e) {
            let windowWidth = e.target.outerWidth;
            console.log(windowWidth);
            this.currentPerView = this.glide._o.perView;
            if(this.orientation === "horizontal") {
                this.currentOrientation = "horizontal";
            } else {
                if((this.orientation === "vertical") && (parseInt(this.verticalBreakpoint) > windowWidth) ) {
                    console.log('toggle currentOrientation');
                    this.currentOrientation = "horizontal";
                } else {
                    this.currentOrientation = "vertical";
                }
            }
        },
        enableCarousel() {
            return (this.currentPerView < this.items.length && this.currentOrientation === "horizontal");
        }
    }
}
</script>
