import {createApp} from 'vue';
import PrimeVue from 'primevue/config';
import themeComponents from './components/global';
import childComponents from '../../../wellthywarrior-child/assets/scripts/components/global';

import themConfig from './../config.json';
const themeData = window[themConfig.theme_prefix + 'data'];

// If the child theme is active, merge the components
const childThemeIsActive = Boolean(themeData.is_child_theme_active);
let components = themeComponents;
if(childThemeIsActive) {
    components = { ...themeComponents, ...childComponents };
}

// remove any components not enabled
const excludedComponents = themeData.excluded_components;
for(let i = 0; i < excludedComponents.length; i++) {
    delete components[excludedComponents[i]];
}

// Get all vue instance wrapping elements
const vueInstanceWrappers = document.querySelectorAll(".vue-instance");

//
// Loop through the instance wrapping elements and determine if they are the child of another.
// If they have not parent wrapping element, then they are a top level wrapper and
// We will create a vue instance out of them.
let instanceContainers = []
for (let i = 0; i < vueInstanceWrappers.length; i++) {
    let wrapper = vueInstanceWrappers[i];
    let parentWrapper = wrapper.parentElement.closest(".vue-instance")
    if(!parentWrapper) {
        instanceContainers.push(wrapper);
    }
}

// Initialize a variable for holding a list of component tags for each Vue instance
let vueInstanceComponentTags = [];

//
// Recursive function that loops through all the child elements of the
// instance container and gathers the custom element tag names into an array
function getVueInstanceComponentTags(instance, node) {

    for (let i = 0; i < node.childNodes.length; i++) {
        let child = node.childNodes[i];
        getVueInstanceComponentTags(instance, child);

        if (child.tagName) {
            let tagName = child.tagName.toLowerCase();

            if (tagName.startsWith('fc-')) {
                vueInstanceComponentTags[instance].push(tagName);
            }
        }
    }
}

// Initialize a variable for containing all the vue instances
let vueInstances = [];

// Loop through each of
for (let i = 0; i < instanceContainers.length; i++) {

    // Add a key to the vueInstanceComponentTags variable to hold this instances list of tags
    vueInstanceComponentTags[i] = [];

    // Create the new Vue instance
    vueInstances[i] = createApp({});

    // get an array of vue instance component tags
    getVueInstanceComponentTags(i, instanceContainers[i]);

    // Filter out any redundant tags
    let uniqueVueInstanceComponentTags = vueInstanceComponentTags[i].reduce(function (reduced, current) {
        if (!reduced.includes(current))
            reduced.push(current);
        return reduced;
    }, []);

    // Loop through the custom element tags found inside the instance container
    for (let j = 0; j < uniqueVueInstanceComponentTags.length; j++) {

        // Register Vue components for each of the custom element tags
        for (const [name, component] of Object.entries(components)) {
            if (name === uniqueVueInstanceComponentTags[j]) {
                vueInstances[i].component(name, component);
            }
        }
    }

    // Mount it!
    vueInstances[i].use(PrimeVue);
    vueInstances[i].mount(instanceContainers[i]);
}
