<template>
  <div v-if="exists" class="module session">
    <div class="module-header">
      <div class="meta">
        <span class="date">{{ formatDate(sessionDateTime) }}</span> @<span class="time">{{ formatTime(sessionDateTime)}} (EST)</span>, <span class="type">{{ session.type}}</span> at <span class="location">{{ session.location}}</span>
      </div>
      <Button size="small"  v-show="!editing" @click="editSession">Edit Session</Button>
    </div>
    <div class="module-body">
      <fieldset v-show="editing" class="flex-cols gap-10 flex-cols-3">
        <Calendar
            :modelValue="sessionDateTime"
            @update:modelValue="updateSessionDateTime"
            dateFormat="yy-mm-dd"
            showTime
            :stepMinute=5
            hourFormat=12  />
        <Dropdown v-model="session.type"
                  :options="[{label: 'Virtual', value: 'virtual'}, {label: 'In-Person', value: 'in-person'}]"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select a type" />
        <Dropdown v-model="session.location"
                  :options="[{label: 'Home', value: 'home'}, {label: 'Office', value: 'office'}]"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select a location" />
      </fieldset>
      <Notes v-show="!editing" :notes="session.notes" parent-model="session" :parent-id="session.ID"/>
    </div>
    <div class="module-footer">
      <Button size="small" v-show="editing" @click="updateSession(session)">Update</Button>
      <Button size="small" severity="danger"  v-show="editing" @click="deleteSession(session)">Delete</Button>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import axios from "axios";
import Button from "primevue/button";
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Notes from "./Notes.vue";

export default {
  components: {
    Notes,
    Button,
    Calendar,
    Dropdown
  },
  props:      {
    session: {},
    parentObject: ''
  },
  data() {
    return {
      editing: false,
      exists: true,
      // sessionDateTime: new Date(this.session.date_time + ' ' + 'GMT+0000')
      sessionDateTime: new Date(this.session.date_time)
    };
  },
  methods:  {
    subtractHours(date) {
      let timeZoneOffset = date.getTimezoneOffset()/60;// Determine timezone from browser
      const dateCopy = new Date(date);
      dateCopy.setHours(dateCopy.getHours() - timeZoneOffset);
      return dateCopy;
    },
    updateSessionDateTime(sessionDateTime) {
      this.sessionDateTime = sessionDateTime;
      this.session.date_time = this.subtractHours(sessionDateTime);
      console.log(this.session.date_time);
    },
    formatDate(date) {
      return date.toLocaleDateString('en-us', {
        year: "numeric", month: "short", day: "numeric"
      })
    },
    formatTime(time) {
      this.subtractHours(time);
      return time.toLocaleTimeString("en-US");
    },
    editSession() {
      this.editing = true;
      this.$emit('stateChange', {editing: true});
    },
    updateSession(session) {
      let data = {
        action: "update_fc_" + "mc_sessions",
      };

      for (const [key, value] of Object.entries(session)) {
        data[key] = value
      }
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            console.log(response.data)
            this.editing = false;
            this.$emit('stateChange', {editing: false});
          })
          .catch((error) => {
            console.log(error)
          });

    },
    deleteSession(session) {
      let data = {
        action: "destroy_fc_" + "mc_sessions",
        data:   session,
      };
      axios.post(
          window.fc_data.ajax_url,
          qs.stringify(data)
      )
          .then((response) => {
            // console.log(response.data)
            this.exists = false;
            this.$emit('stateChange', {editing: false});
          })
          .catch((error) => {
            console.log(error)
          });
    }
  }
}
</script>