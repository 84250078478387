<template>
    <ul>
        <li v-for="(item, index) in items">
            <a :href="item.href" @click="setToActive(index)" :class="{active: item.active}">{{ item.title}}</a>
        </li>
    </ul>
</template>

<script>

export default {
    data() {
        return {
            items: this.navItems,
            anchor:  (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null
        };
    },

    props: {
        navItems: {
            type: Array,
            default: []
        }
    },


    mounted() {
        console.log(this.navItems);
        console.log(this.anchor);
        this.matchToAnchor()
    },

    methods: {
        matchToAnchor() {
            for(let i = 0; i < this.items.length; i++) {
                if(this.items[i].href === '#' + this.anchor) {
                    this.items[i].active = true
                }
            }
        },
        setToActive(index) {
            for(let i = 0; i < this.items.length; i++) {
                if(i === index) {
                    this.items[i].active = true
                } else {
                    this.items[i].active = false
                }
            }
        },
    }
}

</script>
