<template>
    <span class="taxonomy-label" :class="$parent.$parent.$parent.$parent.$parent.cardSettings.taxonomies[taxonomy.plural_name].label_position">
      {{ parseInt(taxonomy.multi_select) ?  taxonomy.plural_name : taxonomy.name }}
    </span>
</template>

<script>
export default {
  props:   {
    taxonomy: {
      type:    Object,
      default: {}
    }
  }
}
</script>